import React from "react"
import axios from "axios"
import xmlParser from "xml2js"
import moment from "moment"
import Comment from "./Comment";

class LastComments extends React.Component {

    constructor(props) {
        super();
        moment.locale('uk')
        this.disqus_id = props.disqus_id
        this.domain = props.domain
        this.state = {
            status: 'loading',
            comments: []
        }
    }

    componentDidMount() {
        let setComments = this.setComments.bind(this)
        let processComments = this.processComments.bind(this)
        const direct = false
        const disqusRss = direct ? `https://${this.disqus_id}.disqus.com/latest.rss` : Routes.comments_path('json')
        axios
            .get(disqusRss)
            .then(response => {
                if (direct) {
                    xmlParser.parseString(response.data.body,
                        {
                            stripPrefix: true,
                            explicitArray: false,
                            ignoreAttrs: true
                        },
                        function (err, data) {
                            const items = data.rss.channel.item
                            setComments(processComments(items))
                        })
                } else {
                    this.setComments(this.processComments(response.data))
                }
            })
            .catch(e => {
                this.setState({
                    status: 'error',
                    message: I18n.t('general.no_data')
                })
            })
    }

    processComments(items) {
        let comments = []
        const domain = this.domain
        const offset = 240
        const weeksAgo = moment().subtract({weeks: 3})

        for (let i = 0; i < items.length && comments.length < 10; i++) {
            const item = items[i]

            const commentDate = moment(item.pubDate).add(offset, 'm')
            if (commentDate < weeksAgo || (domain && item.link.indexOf(domain) === -1)) {
                continue
            }

            const comment = {
                author: item['dc:creator'],
                title: item.title.replace(/re: /ig, '').trim(),
                link: item.link,
                date: commentDate.fromNow()
            }
            comments.push(comment)
        }

        return comments
    }

    setComments(comments) {
        this.setState({
            status: 'loaded',
            comments: comments
        })

    }

    progressBar() {
        return <div className="progress m-t-3 m-l-3 m-r-3">
            <div className="progress-bar progress-bar-grey progress-bar-striped active" style={{width: '100%'}}/>
        </div>
    }

    commentList() {
        return <ul className='list-group m-b-0 list comment-list'>
            {this.state.comments.map((comment, i) =>
                <Comment
                    key={i}
                    author={comment.author}
                    title={comment.title}
                    link={comment.link}
                    date={comment.date}/>
            )}
        </ul>
    }

    noData() {
        return <div className='no-data'>{I18n.t('general.no_data')}</div>
    }

    loading() {
        return <div></div>
    }

    randomErrorMessage (msg) {
        return <div className='no-data'>{msg}</div>
    }

    noCommentsTemplate() {
        const msg = 'С загрузкой комментарив сегодня что-то не задалось. Но в последний раз они выглядили примерно так:'
        return <div>
            <div className='no-data'>{msg}</div>
            <ul className='list-group m-b-0 list comment-list'>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Клыжка</div>
                    <div className='comment-title'>Шутка про горох</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Клыжка</div>
                    <div className='comment-title'>Шутка про лазанью</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Клыжка</div>
                    <div className='comment-title'>Шутка про лазанью с горохом</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Сафонов</div>
                    <div className='comment-title'>Шутка про шубу</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Не Сафонов</div>
                    <div className='comment-title'>Шутка про Сафонова</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Гайструк</div>
                    <div className='comment-title'>Шутка про нормального пацана</div>
                </li>
                <li className='list-group-item'>
                    <div className='text-default comment-author'>Гребенюк</div>
                    <div className='comment-title'>Шутка про что-то отложенное</div>
                </li>
            </ul>
        </div>
    }

    showNoCommentsInfo() {
        const msgs = [
            'При загрузке комментариев что-то пошло не так. Свяжитесь с Клыжкой напрямую, чтоб быть в курсе всех последних шуток.',
            'Не удалось загрузить ни одного информативного комментария.',
            'Ошибка при загрузке комментариев, Наши лучшие инженеры уже пишут шутки, чтоб восполнить этот пробел.',
            'Прошлая система комментариев была слишком хороша.',
        ]

        const items = [
            this.randomErrorMessage.bind(this, msgs[0]),
            this.randomErrorMessage.bind(this, msgs[1]),
            this.randomErrorMessage.bind(this, msgs[2]),
            this.noCommentsTemplate,
            this.randomErrorMessage.bind(this, msgs[0]),
            this.randomErrorMessage.bind(this, msgs[1]),
            this.randomErrorMessage.bind(this, msgs[2]),
            this.noCommentsTemplate,
            this.randomErrorMessage.bind(this, msgs[0]),
            this.randomErrorMessage.bind(this, msgs[1]),
            this.randomErrorMessage.bind(this, msgs[2]),
            this.noCommentsTemplate,
            this.randomErrorMessage.bind(this, msgs[0]),
            this.randomErrorMessage.bind(this, msgs[1]),
            this.randomErrorMessage.bind(this, msgs[2]),
            this.noCommentsTemplate,
            this.randomErrorMessage.bind(this, msgs[3])
        ]

        return items[Math.floor(Math.random()*items.length)]()
    }

    render () {
        let view
        switch (this.state.status) {
            case 'loading':
                view = this.loading()
                break;
            case 'loaded':
                view = this.state.comments.length > 0 ? this.commentList() : this.noData()
                break;
            case 'error':
                // view = this.showNoCommentsInfo()
                view = this.noData()
                break;
            default:
                view = ''
        }

        return view
    }
}

export default LastComments
