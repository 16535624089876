const eventIconMap = {
    goal: 'icon-ball',
    goal_6m: 'icon-ball',
    goal_10m: 'icon-ball',
    missed_goal_6m: 'icon-ball grey',
    missed_goal_10m: 'icon-ball grey',
    card: 'icon-card',
    yellow_card: 'icon-card',
    red_card: 'icon-card red',
    own_goal: 'icon-ball missed',
    penalty: 'icon-pen',
    missed_penalty: 'icon-pen missed',
}

export default eventIconMap
