import React, { useEffect, useState } from "react"
import ApiModule from "../ApiModule"
import Cards from "./Sections/Cards"
import Goals from "./Sections/Goals"
import Lineups from "./Lineups/Lineups"
import Timeline from "./Timeline/Timeline"
import eventsMap from "./EventsMap"
import Penalty from "./Sections/Penalty"

const Match = ({ matchId, title, holder = '' }) => {
    const { ASSIST, GOAL, GOAL_6M, GOAL_10M, OWN_GOAL, 
        YELLOW_CARD, RED_CARD, PENALTY, MISSED_PENALTY } = eventsMap

    const [matchData, setMatchData] = useState({})
    const [players, setPlayers] = useState({})
    const [goals, setGoals] = useState({ host: [], guest: [] })
    const [cards, setCards] = useState({ host: [], guest: [] })
    const [penalties, setPenalties] = useState({ host: [], guest: [] })
    const [teamEvents, setTeamEvents] = useState({ host: {}, guest: {} })
    const [timeline, setTimeline] = useState([])

    const fetchData = () => {
        const api = new ApiModule(false)
        api.get('matches', matchId).then(composeData)
    }

    const composeData = (data) => {
        setMatchData(data)
        composeGoalsBlock(data)
        composeCardsBlock(data)
        composePenaltiesBlock(data)
        composePlayers(data)
        composePlayersEvents(data)
        composeTimeline(data)
    }

    const composePlayers = (data) => {
        const playerList = {}
        data.host_team.players.forEach(player => {
            playerList[player.id] = player
        })

        data.guest_team.players.forEach(player => {
            playerList[player.id] = player
        })
        setPlayers(playerList)
    }

    const composeGoalsBlock = (data) => {
        setGoals(eventFilter(data, [GOAL, GOAL_6M, GOAL_10M, OWN_GOAL]))
    }

    const composeCardsBlock = (data) => {
        setCards(eventFilter(data, [YELLOW_CARD, RED_CARD]))
    }

    const composePenaltiesBlock = (data) => {
        setPenalties(eventFilter(data, [PENALTY, MISSED_PENALTY]))
    }

    const composePlayersEvents = (data) => {
        const teamEvents = {
            host: teamEventsComposer(data.host_team.timeline),
            guest: teamEventsComposer(data.guest_team.timeline)
        }
        setTeamEvents(teamEvents)

    }

    const composeTimeline = (data) => {
        const hostAssists = data.host_team.timeline.filter(event => event.event_type === ASSIST)
        const guestAssists = data.guest_team.timeline.filter(event => event.event_type === ASSIST)

        const hostEvents = data.host_team.timeline
            .filter(event => ![PENALTY, MISSED_PENALTY, ASSIST].includes(event.event_type))
            .map(event => timelineComposer('host', event, hostAssists))

        
        const guestEvents = data.guest_team.timeline
            .filter(event => ![PENALTY, MISSED_PENALTY, ASSIST].includes(event.event_type))
            .map(event => timelineComposer('guest', event, guestAssists))

        const matchTimeline = [...hostEvents, ...guestEvents]
        matchTimeline.sort((a, b) => (a.minute - b.minute))
        setTimeline(matchTimeline)
    }

    const timelineComposer = (side, event, assists) => {
        let assist = null
        if (event.event_type === GOAL && assists) {
            const index = assists.findIndex(assist => assist.minute === event.minute)
            if (index >= 0) {
                assist = {...assists[index]}
                assists.splice(index, 1)
            }
        }

        return {
            id: event.id,
            side,
            type: event.event_type,
            assist, 
            minute: event.minute,
            player_id: event.footballer_id
        }
    }

    const eventFilter = (data, types) => (
        {
            host: data.host_team.timeline.filter(event => types.includes(event.event_type)),
            guest: data.guest_team.timeline.filter(event => types.includes(event.event_type))
        }
    )

    const teamEventsComposer = (timeline) => {
        const teamEvents = {}
        timeline.forEach(event => {
            if (!teamEvents[event.footballer_id]) {
                teamEvents[event.footballer_id] = {
                    goal: 0,
                    own_goal: 0,
                    yellow_card: 0,
                    red_card: 0,
                }
            }

            let event_type
            switch (event.event_type) {
                case GOAL:
                case GOAL_6M:
                case GOAL_10M:
                    event_type = GOAL
                    break;
                case OWN_GOAL:
                case YELLOW_CARD:
                case RED_CARD:
                    event_type = event.event_type
                    break;
            }

            if (event_type) {
                teamEvents[event.footballer_id][event_type]++
            }
        })
        return teamEvents
    }

    useEffect(() => {
        fetchData()
    }, [])

    return <section className='match-section'>
        <div className='match-info'>
            <div className='content-box match-box widget'>
                <div className='heading'>
                    <h2>{title}</h2>
                </div>
                <div dangerouslySetInnerHTML={{__html: holder}} />

                <Goals goals={goals} players={players}/>
                <Cards cards={cards} players={players}/>
                <Penalty penalties={penalties} players={players}/>
            </div>

            <Timeline data={matchData} timeline={timeline} players={players}/>
        </div>

        <Lineups data={matchData} teamEvents={teamEvents} />
    </section>
}

export default Match
