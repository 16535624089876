import React from "react"
import ProtocolCorrectionForm from "./ProtocolCorrectionForm";
class ProtocolCorrection extends React.Component {

    constructor() {
        super();
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    handleFormSubmit(e) {
        $('#' + this.props.modalId).modal('hide')
    }

  render () {
    return <div className="modal fade" id={this.props.modalId} tabIndex="-1">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <button type="button" className="close" data-dismiss="modal">×</button>
                    <h4 className="modal-title" id="myModalLabel">{I18n.t('protocols.protocol_mistake')}</h4>
                </div>
                <div className="modal-body">
                    <h4 className="m-t-0">{this.props.title}</h4>
                    <ProtocolCorrectionForm match={this.props.match} onFormSubmit={this.handleFormSubmit}/>
                </div>
            </div>
        </div>
    </div>
  }
}

export default ProtocolCorrection
