import React from "react"
import moment from 'moment'
import FaIcon from "../../general/FaIcon";

class Game extends React.Component {
    constructor(props){
        super(props);
        this.game = props.game
    }

    getTourOptions () {
        let tourOptions = []
        let tours = this.props.tours
        for (let id in tours) {
            if (tours.hasOwnProperty(id)) {
                let tour = tours[id]
                tourOptions.push(<option key={id} value={tour.id}>{tour.name}</option>)
            }
        }

        return tourOptions
    }

    getVenueOptions () {
        let options = [<option key={-1} value={null}/>]
      
        if (this.props.venues.forEach) {
            this.props.venues.forEach(venue => {
                options.push(<option key={venue.id} value={venue.id}>{venue['short-name']}</option>)
            })
        }

        return options;
    }

    getTeamOptions (tag) {
        let teamOptions = []
        let teams = this.props.teams

        if (tag) {
            // data-select2-tag="true"
            teamOptions.push(<option key={tag} value={tag}>{tag}</option>)
        }

        for (let id in teams) {
            if (teams.hasOwnProperty(id)) {
                let team = teams[id]
                teamOptions.push(<option key={id} value={team.id}>{team.name}</option>)
            }
        }

        return teamOptions
    }

    getViewLine () {
        const deleting = this.props.inDeleting
        let btnClasses = 'btn btn-xs btn-outline btn-outline-colorless text-lowercase'
        let btnsDisabled = false
        if (deleting) {
            btnClasses += ' btn-loading'
            btnsDisabled = true
        }

        let hostTeamName
        let hostClass = ''
        if (this.game.hosts && this.game.hosts.name) {
            hostTeamName = this.game.hosts.name
        } else {
            hostTeamName = this.game['host-draft-team']
            hostClass = 'text-danger'
        }

        let guestTeamName
        let guestClass = ''
        if (this.game.guests && this.game.guests.name) {
            guestTeamName = this.game.guests.name
        } else {
            guestTeamName = this.game['guest-draft-team']
            guestClass = 'text-danger'
        }

        // const hostTeamName = (this.game.hosts && this.game.hosts.name) || this.game['host-draft-team']
        // const guestTeamName = (this.game.guests && this.game.guests.name) || this.game['guest-draft-team']

        const draftClass = (this.game.hosts && this.game.guests) ? '' : 'bg-warning-light'

        return <tr className={draftClass}>
            <td>{this.game['match-at']}</td>
            <td>{this.game.venue['short-name']}</td>
            <td>{this.game.tour.name}</td>
            <td className={hostClass}>{hostTeamName}</td>
            <td className={guestClass}>{guestTeamName}</td>
            <td className='text-nowrap text-right'>
                <button className={`btn-info ${btnClasses}`}
                        disabled={btnsDisabled}
                        onClick={this.props.onEditClick.bind(this, this.game.id)}>
                    <FaIcon icon='pencil'/>
                </button>
                &nbsp;
                <button className={`btn-danger ${btnClasses}`}
                        disabled={btnsDisabled}
                        onClick={this.props.onRemoveClick.bind(this, this.game.id)}>
                    <FaIcon icon='trash'/>
                </button>
            </td>
        </tr>
    }

    getEditLine () {
      const venueOptions = this.getVenueOptions()
      const tourOptions = this.getTourOptions()
      const hostTeamOptions = this.getTeamOptions(!this.game.hosts && this.game['host-draft-team'])
      const guestTeamOptions = this.getTeamOptions(!this.game.guests && this.game['guest-draft-team'])

      const matchAt = moment(this.game['match-on']).format('DD.MM.YY')
      const rowId = 'schedule-row-' + this.game.id
      const host = (this.game.hosts && this.game.hosts.id) || this.game['host-draft-team']
      const guest = (this.game.guests && this.game.guests.id) || this.game['guest-draft-team']

      return <tr id={rowId}>
          <td className='fit'>
              <div className='game-date-time'>
                  <input type="text" className="masked-input-date form-control input-sm m-b-0 game-date"
                          defaultValue={matchAt}
                          placeholder={I18n.t('placeholder.date')}/>
                  <input type="text" className="masked-input-time form-control input-sm m-b-0 game-time"
                          defaultValue={this.game['match-at']}
                          placeholder={I18n.t('placeholder.time')}/>
              </div>
          </td>

          <td>
            <select className="form-control select2-sm select2-base game-venue"
                      data-allow-clear="false"
                      defaultValue={this.game.venue.id}
                      data-tags="true">
                  {venueOptions}
            </select>
          </td>

          <td>
              <select className="form-control select2-sm select2-base game-tour"
                      data-allow-clear="false"
                      defaultValue={this.game.tour.id}
                      data-tags="true">
                  {tourOptions}
              </select>
          </td>

          <td>
              <select className="form-control select2-sm select2-base game-host"
                      data-allow-clear="false"
                      defaultValue={host}
                      data-tags="true">
                  {hostTeamOptions}
              </select>
          </td>

          <td>
              <select className="form-control select2-sm select2-base game-guest"
                      data-allow-clear="false"
                      defaultValue={guest}
                      data-tags="true">
                  {guestTeamOptions}
              </select>
          </td>

          <td className='text-nowrap text-right'>
              <input type='hidden' className='game-venue' value={this.game.venue.id}/>
              <button className='btn btn-xs btn-success btn-outline btn-outline-colorless text-lowercase'
                      onClick={this.props.onSaveClick.bind(this, this.game.id)}>
                  <FaIcon icon='check'/>
              </button>
              &nbsp;
              <button className='btn btn-xs btn-warning btn-outline btn-outline-colorless text-lowercase'
                      onClick={this.props.onCancelEditClick.bind(this, this.game.id)}>
                  <FaIcon icon='close'/>
              </button>
          </td>
      </tr>
    }

    render () {
        return this.props.inEdit ? this.getEditLine() : this.getViewLine()
    }
}

export default Game
