import React from "react"
import eventIconMap from "../EventIconsMap"
import AdditionalInfo from "./AdditionalInfo"
import PlayerName from "./PlayerName"

const EventSection = ({ events = [], players, showMinute = true }) => {
    if (Object.keys(players).length === 0) {
        return null
    }

    return <div className='col'>
        <ul className='list'>
            {events.map(event => (
                <li key={event.id}>
                    <span className={eventIconMap[event.event_type]}></span>
                    { showMinute &&
                        <span className='minute'>
                            {event.minute}’
                        </span>
                    }
                    <div className='text-nowrap'>
                        <PlayerName player={players[event.footballer_id]}/>
                        <AdditionalInfo event={event}/>
                    </div>
                </li>
            ))}
        </ul>
    </div>
}

export default EventSection
