import React from "react"
import Event from "./Event"

const Cards = ({ cards, players }) => {
    if (cards.host.length + cards.guest.length === 0) {
        return null
    }

    return <div className='match-block'>
        <h3>{I18n.t('matches.cards')}</h3>
        <div className='block-holder'>
            <Event events={cards.host} players={players} />
            <Event events={cards.guest} players={players} />
        </div>
    </div>
}

export default Cards
