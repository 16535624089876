import React from "react"

class NewDayGame extends React.Component {
    constructor(props){
        super(props)
        this.venueId = null
    }

    getTourOptions () {
        let tourOptions = [<option key={-1} value={null}/>]
        let tours = this.props.tours

        for (let id in tours) {
            if (tours.hasOwnProperty(id)) {
                let tour = tours[id]
                tourOptions.push(<option key={id} value={tour.id}>{tour.name}</option>)
            }
        }

        return tourOptions
    }

    getVenueOptions () {
      let options = [<option key={-1} value={null}/>]
      
      if (this.props.venues.forEach) {
        this.props.venues.forEach(venue => {
          options.push(<option key={venue.id} value={venue.id}>{venue['short-name']}</option>)
        })
      }

      return options;
    }

    getTeamOptions () {
        let teamOptions = [<option key={-1} value={null}/>]
        let teams = this.props.teams

        for (let id in teams) {
            if (teams.hasOwnProperty(id)) {
                let team = teams[id]
                teamOptions.push(<option key={id} value={team.id}>{team.name}</option>)
            }
        }

        return teamOptions
    }

    render () {
        const venueOptions = this.getVenueOptions()
        const tourOptions = this.getTourOptions()
        const teamOptions = this.getTeamOptions()

        return <div className='new-game'>
            <form action='#' onSubmit={this.props.onNewSlotSubmit}>
                <div className='row'>
                    <div className='form-group col-md-3 m-b-0 game-date-time'>
                        <input type="text" className="masked-input-date form-control input-sm new-game-date game-date"
                               placeholder={I18n.t('placeholder.date')}/>
                        <input type="text"
                               className="masked-input-time form-control input-sm game-time new-game-time game-time"
                               defaultValue={this.props.startTime}
                               placeholder={I18n.t('placeholder.time')}/>
                    </div>

                    <div className='form-group col-md-2 m-b-0'>
                        <select className="form-control select2-sm select2-base new-game-venue"
                                defaultValue={9} // ХИРЭ
                                data-placeholder={I18n.t('th.venue')}
                                data-allow-clear="false">
                            {venueOptions}
                        </select>
                    </div>

                    <div className='form-group col-md-2 m-b-0'>
                        <select className="form-control select2-sm select2-base new-game-tour"
                                defaultValue={this.props.lastTour}
                                data-placeholder={I18n.t('th.tour')}
                                data-allow-clear="false">
                            {tourOptions}
                        </select>
                    </div>

                    <div className='form-group col-md-2 m-b-0'>
                        <select className="form-control select2-sm select2-base new-game-host"
                                data-placeholder={I18n.t('placeholder.host_team')}
                                data-allow-clear="false"
                                data-tags="true">
                            {teamOptions}
                        </select>
                    </div>

                    <div className='form-group col-md-2 m-b-0'>
                        <select className="form-control select2-sm select2-base new-game-guest"
                                data-placeholder={I18n.t('placeholder.guest_team')}
                                data-allow-clear="false"
                                data-tags="true">
                            {teamOptions}
                        </select>
                    </div>

                    <div className='form-group col-md-1 m-b-0'>
                        <input type='hidden' className='new-game-venue' defaultValue={this.venueId}/>
                        <button type='submit' className='btn btn-sm btn-primary btn-outline pull-right'>{I18n.t('buttons.add')}</button>
                    </div>
                </div>
            </form>
        </div>
    }
}

export default NewDayGame
