import React from "react"
import EnterInput from "../../general/EnterInput";

class NewRow extends React.Component {

    constructor() {
        super();
    }

    render () {
        const addButtonTitle = `${I18n.t('buttons.add')} ${I18n.t('admin.stages.stage')}`
        const addButton = <button className='btn btn-success btn-xs' onClick={this.props.handleCreateOnClick}>
            {addButtonTitle}
        </button>


        return <tr id='new-stage' className='success'>
            <td>
                <EnterInput type="text" className="form-control input-sm m-b-0"
                            name='stage-name'
                            placeholder={I18n.t('general.title')}
                            onEnter={this.props.handleCreateOnClick}/>
            </td>
            <td>
                <label className="custom-control custom-checkbox" htmlFor="playoff">
                    <input type="checkbox"
                           id="playoff"
                           name='stage-playoff'
                           className="custom-control-input"/>
                    <span className="custom-control-indicator"/>
                    {I18n.t('admin.stages.playoff')}
                </label>
            </td>
            <td className='fit'>
                <div className='pull-right'>
                    {addButton}
                </div>
            </td>
        </tr>
    }

}

export default NewRow
