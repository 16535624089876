import React from "react"
import Event from "./Event"

const Goals = ({ goals, players }) => {
    if (goals.host.length + goals.guest.length === 0) {
        return null
    }

    return <div className='match-block'>
        <h3>{I18n.t('matches.goals')}</h3>
        <div className='block-holder'>
            <Event events={goals.host} players={players}/>
            <Event events={goals.guest} players={players}/>
        </div>
    </div>
}

export default Goals
