import React from "react"
import axios from 'axios'

class ProtocolCorrectionForm extends React.Component {

    constructor() {
        super();
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }

    handleFormSubmit(e) {
        e.preventDefault()
        const form = $(e.target)
        const matchId = form.find("[name='match']").val()
        const text = form.find("[name='text']").val()
        const token = $("meta[name='csrf-token']").attr("content")

        axios.post(Routes.match_suggestion_path({ match_id: matchId }),
            { text: text },
            { headers: {'X-CSRF-Token': token }})
            .then(function (response) {
                form.find("[name='text']").val('')
            })
            .catch(function (error) {
                form.find("[name='text']").val('')
            });
        UnobtrusiveFlash.showFlashMessage('Предложение об исправлении отправлено', {type: 'notice'})
        this.props.onFormSubmit(e)
    }

    render () {
        return <form action='#' onSubmit={this.handleFormSubmit}>
            <div className="form-group">
                <label htmlFor="text" className='font-weight-normal'>{I18n.t('protocols.correction')}:</label>
                <textarea className="form-control" id="text" name='text'/>
            </div>
            <div>
                <input type='hidden' name='match' value={this.props.match}/>
                <button type="submit" className="btn">{I18n.t('buttons.submit')}</button>
            </div>
        </form>
    }
}

export default ProtocolCorrectionForm
