import React from "react"

const Matches = ({ matches, selectedMatchId, loading, colClass, onMatchClick}) => {
    const hostPoint = (data, field) => sidePoints(data, field, 'host')
    const guestPoint = (data, field) => sidePoints(data, field, 'guest')

    const sidePoints = (data, field, side) => {
        side = `${side}-points`
        
        let point = data[side] && data[side][field]
        if (point === undefined || point === null || isNaN(point)) {
            point = null
        } else {
            point = parseInt(point, 10)
        }
        
        return point
    }

    const matchTitle = (match) => {
        const winner = getWinner(match)
        let hostClasses
        let guestClasses
        
        switch (winner) {
            case 'host':
                hostClasses = 'text-success'
                guestClasses = 'text-danger'
                break
            case 'guest':
                hostClasses = 'text-danger'
                guestClasses = 'text-success'
                break
            default:
                hostClasses = ''
                guestClasses = ''
                break
        }

        const hostTeamName = (match.hosts && match.hosts.name) || match['host-draft-team']
        const guestTeamName = (match.guests && match.guests.name) || match['guest-draft-team']

        let hostTeam = <span className={hostClasses}>{hostTeamName}</span>
        let guestTeam = <span className={guestClasses}>{guestTeamName}</span>
        
        return <span>{hostTeam} - {guestTeam}</span>
    }

    const getWinner = (match) => {
        let hostGoals = hostPoint(match, 'goal')
        let guestGoals = guestPoint(match, 'goal')
        const hostPenalty = hostPoint(match, 'penalty')
        const guestPenalty = guestPoint(match, 'penalty')

        if (hostGoals === null || guestGoals === null) {
            return null
        }

        if (hostGoals === guestGoals) {
            if (hostPenalty === null || guestPenalty === null || hostPenalty === guestPenalty) {
                return null
            } else {
                return hostPenalty > guestPenalty ? 'host' : 'guest'
            }
        }

        return hostGoals > guestGoals ? 'host' : 'guest'
    }

    const matchScore = (match) => {
        let hostGoals = hostPoint(match, 'goal')
        let guestGoals = guestPoint(match, 'goal')
        const hostPenalty = hostPoint(match, 'penalty')
        const guestPenalty = guestPoint(match, 'penalty')
        
        if (hostGoals === null) {
            hostGoals = '-'
        }

        if (guestGoals === null) {
            guestGoals = '-'
        }

        let score = `${hostGoals}:${guestGoals}`
        if (hostPenalty > 0 && guestPenalty > 0) {
            score = `${score} (${hostPenalty}:${guestPenalty})`
        }

        return score
    }

    const panelBody = () => {
        return matches.length > 0 ? matchList() : noMatches()
    }

    const matchRow = (schedule) => {
        const matchId = schedule.match.id
        let classList = 'list-group-item'
        if (matchId === selectedMatchId) {
            classList += ' admin-list-group-item-selected font-weight-semibold text-default'
        }

        if (!fulfilled(schedule)) {
            classList += ' list-group-item-warning'
        }

        const attrs = {
            key: schedule.id,
            className: classList,
            href: '#',
            onClick: onMatchClick.bind(this, schedule.match.id),
        }

        return <a { ...attrs }>
            <span className='pull-right game-score'>{matchScore(schedule)}</span>
            {matchTitle(schedule)}
        </a>
    }

    const fulfilled = (match) => {
        const hostGoals = hostPoint(match, 'goal')
        const guestGoals = guestPoint(match, 'goal')

        return hostGoals !== null && guestGoals !== null
    }

    const matchList = () => {
        const loadingClass = loading ? 'form-loading' : ''
        const classes = `list-group compact-list-group ${loadingClass}`
        return <div className={classes}>
            {matches.map(matchRow)}
        </div>
    }

    const noMatches = () => {
        const loadingClass = loading ? 'form-loading' : ''
        const classes = `panel-body ${loadingClass}`
        return <div className={classes}>
            {I18n.t('tours.select_tour')}
        </div>
    }

    return <div className={colClass} id='matches-panel'>
        <div className="panel">
            <div className="panel-heading">
                <span className="panel-title">{I18n.t('titles.matches')}</span>
            </div>

            {panelBody()}
        </div>
    </div>
}

export default Matches
