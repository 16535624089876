import React from "react"
import ApiModule from "../../ApiModule";
import ViewRow from "./ViewRow";
import EditRow from "./EditRow";
import NewRow from "./NewRow";

const RESOURCE = 'seasons'

class Seasons extends React.Component {

    constructor(props) {
        super();
        this.tournamentId = props.tournamentId
        this.api = new ApiModule()
        this.state = {
            list: props.list,
            newSeasonRow: null,
            editRow: -1
        }

        this.handleNewOnClick = this.handleNewOnClick.bind(this)
        this.handleCreateOnClick = this.handleCreateOnClick.bind(this)
        this.handleEditOnClick = this.handleEditOnClick.bind(this)
        this.handleSaveOnClick = this.handleSaveOnClick.bind(this)
        this.handleCancelOnClick = this.handleCancelOnClick.bind(this)
    }

    handleNewOnClick() {
        this.setState({
            newSeasonRow: <NewRow handleCreateOnClick={this.handleCreateOnClick}
                                  handleCancelOnClick={this.handleCancelOnClick}/>,
            editRow: -1
        })
    }

    handleCreateOnClick() {
        const row = $(`#new-season`)
        const season = {
            name: row.find("[name='season-name']").val(),
            description: row.find("[name='season-description']").val(),
            active: row.find("[name='season-status']").is(":checked")
        }

        this.api.create(`tournaments/${this.tournamentId}/${RESOURCE}`, season)
            .then(data => {
                this.setState(prevState => {
                    let list = prevState.list
                    data.id = Number.parseInt(data.id, 10)
                    list.unshift(data)
                    return {
                        newSeasonRow: null,
                        list: list
                    }
                })
            })
    }

    handleEditOnClick(id) {
        this.setState({
            newSeasonRow: null,
            editRow: id
        })
    }

    handleSaveOnClick(id) {
        const row = $(`#season-${id}`)
        const season = {
            name: row.find("[name='season-name']").val(),
            description: row.find("[name='season-description']").val(),
            active: row.find("[name='season-status']").is(":checked")
        }

        this.api.update(RESOURCE, id, season)
            .then(data => {
                this.setState(prevState => {
                    let list = prevState.list
                    list.forEach(item => {
                        if (item.id === Number.parseInt(data.id, 10)) {
                            item.name = data.name
                            item.details = item.details || {}

                            const itemDetails = item.details
                            const dataDetails = data.details

                            itemDetails.description = dataDetails.description
                            itemDetails.active = dataDetails.active
                        }
                    })
                    return {
                        editRow: -1,
                        list: list
                    }
                })
            })
    }

    handleCancelOnClick() {
        this.setState({
            newSeasonRow: null,
            editRow: -1
        })
    }

    seasonList() {
        const list = []
        this.state.list.forEach(season => {
            let row
            const details = season.details || {}
            const rowData = {
                id: season.id,
                name: season.name,
                description: details.description,
                active: details.active === true
            }

            if (season.id === this.state.editRow) {
                row = <EditRow key={rowData.id}
                               handleSaveOnClick={this.handleSaveOnClick}
                               handleCancelOnClick={this.handleCancelOnClick}
                               data={rowData}/>
            } else {
                row = <ViewRow key={rowData.id}
                               handleEditOnClick={this.handleEditOnClick}
                               data={rowData}/>
            }

            list.push(row)
        })

        return list
    }

    render () {
        return <div className='table-responsive'>
            <table className='table table-bordered table-striped'>
                <thead>
                    <tr>
                        <th>{I18n.t('general.title')}</th>
                        <th className='hidden-xs'>{I18n.t('general.description')}</th>
                        <th>{I18n.t('general.status')}</th>
                        <th>
                            <div className='pull-right'>
                                <button className='btn btn-success btn-xs' disabled={!!this.state.newSeasonRow} onClick={this.handleNewOnClick}>
                                    {I18n.t('admin.seasons.add_season')}
                                </button>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.newSeasonRow}
                    {this.seasonList()}
                </tbody>
            </table>
        </div>
    }

}

export default Seasons
