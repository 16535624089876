import React from "react"
class NoDataRow extends React.Component {
    constructor(props) {
        super();
        this.colspan = props.colspan || 2
    }

    render () {
        return <tr>
            <td colSpan={this.colspan}>
                    <span className='font-italic'>
                        {this.props.text}
                    </span>
            </td>
        </tr>
    }
}

export default NoDataRow
