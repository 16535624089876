import React from "react"
import IconButon from "../../../general/IconButon"

class ViewRow extends React.Component {

    constructor(props) {
        super();
        this.data = props.data
    }

    render () {
        const id = this.data.id
        const editButton = <IconButon icon='pencil' type='primary' outline={true} handleOnClick={this.props.handleEditOnClick.bind(this, id)}/>
        const deleteButton = <IconButon icon='trash' type='danger' outline={true} handleOnClick={this.props.handleDeleteOnClick.bind(this, id)}/>

        let rowClass
        switch (id) {
           case this.props.toDeleteRow:
               rowClass = 'danger'
               break
            case this.props.selectedRow:
                rowClass = 'info'
                break
            default:
                rowClass = ''
       }

        return <tr data-id={id} className={rowClass}>
            <td>
                <i className="fa fa-icon icon-move m-r-1 fa-arrows text-light sortable-icon"/>
                {this.data.name}
            </td>
            <td className='fit text-center'>
                {editButton}
                &nbsp;&nbsp;
                {deleteButton}
            </td>
        </tr>
    }

}

export default ViewRow
