import React from "react"
class BootstrapPanel extends React.Component {
  render () {
    return <div className="panel">
        <div className="panel-title">{this.props.title}</div>
        <div className="panel-body">
            {this.props.children}
        </div>
    </div>
  }
}

export default BootstrapPanel
