import React from "react"
class EnterInput extends React.Component {

    constructor(props) {
        super();
        this.classList = Array.isArray(props.className) ? props.className.split(' ') : props.className
        this.handleKeyUp = this.handleKeyUp.bind(this)
    }

    handleKeyUp(e) {
        e.preventDefault()
        switch (e.keyCode) {
            case 13:
                this.callIfExists('onEnter')
                break
            case 27:
                this.callIfExists('onCancel')
                break
        }

        if (e.keyCode === 13) {

        }
    }

    callIfExists(name) {
        if (this.props[name] && typeof(this.props[name] === 'function')) {
            this.props[name]()
        }
    }

    render () {
        return <input type="text"
                      className={this.classList}
                      defaultValue={this.props.defaultValue}
                      autoFocus={this.props.autoFocus}
                      name={this.props.name}
                      placeholder={this.props.placeholder}
                      required={true}
                      onKeyUp={this.handleKeyUp}/>
    }
}

export default EnterInput
