import React from "react"
import IconButon from "../../general/IconButon"
import EnterInput from "../../general/EnterInput";

class EditRow extends React.Component {

    constructor(props) {
        super();
        this.data = props.data
        this.onClick = props.handleSaveOnClick.bind(this, this.data.id)
    }

    render () {
        const saveButton = <IconButon icon='floppy-o' type='success' handleOnClick={this.onClick}/>
        const cancelButton = <IconButon icon='times' handleOnClick={this.props.handleCancelOnClick}/>
        const rowId = `stage-${this.data.id}`

        return <tr key={this.data.id} id={rowId} className='info'>
            <td>
                <EnterInput type="text" className="form-control input-sm m-b-0"
                            defaultValue={this.data.name}
                            autoFocus={true}
                            name='stage-name'
                            placeholder={I18n.t('general.title')}
                            onEnter={this.onClick}
                            onCancel={this.props.handleCancelOnClick}/>
            </td>
            <td>
                <label className="custom-control custom-checkbox" htmlFor="status">
                    <input type="checkbox"
                           defaultChecked={this.data.playoff}
                           id="status"
                           name='stage-playoff'
                           className="custom-control-input"/>
                    <span className="custom-control-indicator"/>
                    {I18n.t('admin.stages.playoff')}
                </label>
            </td>
            <td className='fit text-center'>
                {saveButton}
                &nbsp;&nbsp;
                {cancelButton}
            </td>
        </tr>
    }

}

export default EditRow
