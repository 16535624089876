import React, { useEffect, useState } from "react"
import ApiModule from "../../../ApiModule";
import Player from "./Player";
import { TIMELINE_EVENT_TYPES, GOAL_TYPES } from './PlayerEvents'

const Team = ({ matchId, details, opponentDetails, loading, side, 
    onExpandClick, onScoreUpdate, onPlayerUpdate}) => {

    const api = new ApiModule()
    const { score_points = {}, players = [], timeline = [] } = details
    const opponentTimeline = opponentDetails.timeline || []

    const [goal, setGoal] = useState('')
    const [penalty, setPenalty] = useState('')
    const [foul1, setFoul1] = useState('')
    const [foul2, setFoul2] = useState('')

    const [medGoal, setMedGoal] = useState('')
    const [medPenalty, setMedPenalty] = useState('')
    const [medFoul1, setMedFoul1] = useState('')
    const [medFoul2, setMedFoul2] = useState('')

    const [footballers, setFootballers] = useState([])
    const [attendantPlayerNumbers, setAttendantPlayerNumbers] = useState({})
    const [duplicatedNumbers, setDuplicatedNumbers] = useState([])

    const [synced, setSynced] = useState(true)
    const [mainScoreHasError, setMainScoreHasError] = useState(false)
    const [penaltyScoreHasError, setPenaltyScoreHasError] = useState(false)
    const [scoreHasError, setScoreHasError] = useState(false)

    const [hasDetails, setHasDetails] = useState(false)

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip();
    })

    useEffect(() => {
        if (score_points?.hasOwnProperty('goal')) {
            const g = getInputScoreValue(score_points.goal)
            setGoal(g)
            setMedGoal(g)
            
            const p = getInputScoreValue(score_points.penalty)
            setPenalty(p)
            setMedPenalty(p)

            const f1 = getInputScoreValue(score_points.foul1)
            setFoul1(f1)
            setMedFoul1(f1)

            const f2 = getInputScoreValue(score_points.foul2)
            setFoul2(f2)
            setMedFoul2(f2)
        }
    }, [score_points])

    useEffect(() => {
        setHasDetails(Object.keys(details).length > 0)
    }, [details])

    useEffect(() => {
        if (players.length > 0) {
            setFootballers(players)
        }
    }, [players])

    useEffect(() => {
        if (footballers.length > 0) {
            definePlayerNumbers()
        }
    }, [footballers])

    useEffect(() => {
        if (Object.keys(attendantPlayerNumbers).length > 0) {
            defineDuplicatedNumbers()
        }
    }, [attendantPlayerNumbers])

    useEffect(() => {
        if (timeline.length > 0) {
            const timelineGoals = timeline.filter(line => GOAL_TYPES.includes(line.event_type)).length
            const opponentOwngoals = opponentTimeline.filter(line => line.event_type === TIMELINE_EVENT_TYPES.OWN_GOAL.type).length
            const teamGoals = parseInt(goal, 10)
            setMainScoreHasError(teamGoals !== (timelineGoals + opponentOwngoals))
        } else {
            setMainScoreHasError(false)
        }
    }, [timeline, opponentTimeline, goal])

    useEffect(() => {
        if (timeline.length > 0) {
            const timelinePenalties = timeline.filter(line => line.event_type === TIMELINE_EVENT_TYPES.PENALTY.type).length
            const teamPenalties = parseInt(penalty, 10) || 0
            setPenaltyScoreHasError(teamPenalties !== timelinePenalties)
        } else {
            setPenaltyScoreHasError(false)
        }
    }, [timeline, penalty])

    useEffect(() => {
        setScoreHasError(mainScoreHasError || penaltyScoreHasError)
    }, [mainScoreHasError, penaltyScoreHasError])

    const definePlayerNumbers = () => {
        const numbers = {}
        players.filter(pl => !!pl.attendance)
            .forEach(pl => {
                numbers[pl.id] = parseInt(pl.number, 10)
            })
        setAttendantPlayerNumbers(numbers)
    }

    const defineDuplicatedNumbers = () => {
        const existingNumbers = []
        const duplicates = []
        Object.values(attendantPlayerNumbers).forEach(number => {
            if (isNaN(number) || number < 1) {
                return
            }
            if (existingNumbers.includes(number) && !duplicates.includes(number)) {
                duplicates.push(number)
            } else {
                existingNumbers.push(number)
            }
        })

        setDuplicatedNumbers(duplicates)
    }

    const updateFootballer = (updatedFootballer) => {
        const foots = [ ...footballers ]
        const footballer = foots.find(footballer => footballer.id === updatedFootballer.id)
        footballer.attendance = updatedFootballer.attendance
        footballer.number = updatedFootballer.number
        setFootballers(foots)
    }

    const onPlayerUpdateCallback = () => {
        setSynced(true)
        onPlayerUpdate()
    }

    const scoreChangedHandler = () => {
        if (needToUpdate()) {
            setSynced(false)
            onScoreUpdate(details.id, side, {
                goal,
                penalty,
                foul1,
                foul2,
            }, () => {
                setSynced(true)
                setMediateScores()
            })
        }
    }

    const needToUpdate = () => (
        medGoal !== goal ||
        medPenalty !== penalty ||
        medFoul1 !== foul1 ||
        medFoul2 !== foul2
    )

    const setMediateScores = () => {
        setMedGoal(goal)
        setMedPenalty(penalty)
        setMedFoul1(foul1)
        setMedFoul2(foul2)
    }

    const getInputScoreValue = (value) => (isNaN(parseInt(value)) ? '' : value)

    const growlError = (error) => {
        const errorMessage = error && error.response && error.response.data && error.response.data.error
        || I18n.t('general.unknown_error')

        $.growl.error({
        title: I18n.t('general.not_saved'),
        message: errorMessage
        });
    }

    const handlePlayerAttendanceChanged = (footballer, attendance) => {
        const data = {
            type: 'attendance',
            footballer_id: footballer.id,
            side,
            value: attendance,
        }

        setSynced(false)
        api.update('protocols', matchId, data)
            .then(() => {
                updateFootballer({...footballer, ...{ attendance }})
                setSynced(true)
            })
            .catch(error => growlError(error))
    }

    const handleNumberChanged = (footballer, number) => {
        const data = {
            type: 'number',
            footballer_id: footballer.id,
            side,
            value: number,
        }

        setSynced(false)
        api.update('protocols', matchId, data)
            .then(() => {
                updateFootballer({...footballer, ...{ number }})
                setSynced(true)
            })
            .catch(error => growlError(error))
    }

    const panelTitle = () => {
        if (hasDetails) {
            return <span className="panel-title text-default">
                <a href='#' onClick={onExpandClick}>
                    {details.name}
                </a>
                <span className='pull-right'>
                    {getHeadingErrorIcon()}
                    {getHeadingSyncedIcon()}

                    <a href='#' className='text-muted'>
                        <i className="fa fa-icon fa-arrows-alt" onClick={onExpandClick} />
                    </a>
                </span>
            </span>
        } else {
            return <span className="panel-title">
                {I18n.t(`th.${side}`)}
            </span>
        }
    }

    const getHeadingErrorIcon = () => {
        if (!scoreHasError) {
            return null
        }

        return <span className='m-r-1 text-danger'>
            <i className={`fa fa-icon fa-exclamation-circle`}
                data-toggle='tooltip'
                data-placement='top'
                data-state='danger'
                data-original-title='Проверьте счет' />
        </span>
    }

    const getHeadingSyncedIcon = () => {
        return <span className='m-r-1 text-muted'>
            <i className={`fa fa-icon fa-spinner text-warning ${synced ? 'hidden' : ''}`} />
            <i className={`fa fa-icon fa-check ${synced ? '' : 'hidden'}`}
                data-toggle='tooltip'
                data-placement='top'
                data-original-title='Сохранено' />
        </span>
    }

    const panelBody = () => {
        return hasDetails ? teamDetails() : noTeam()
    }

    const getPlayerList = () => {
        const list = []

        players.forEach(player => (list.push(
            <Player key={player.id}
                footballer={player}
                matchId={matchId}
                side={side}
                events={timeline.filter(line => line.footballer_id === player.id)}
                setSynchedCallback={setSynced}
                onPlayerUpdate={onPlayerUpdateCallback}
                handlePlayerAttendanceChanged={handlePlayerAttendanceChanged}
                duplicatedNumbers={duplicatedNumbers}
                handleNumberChanged={handleNumberChanged} />
            )))
        return <div>{list}</div>
    }

    const getResultsRow = () => {
        return <div className='list-group-item form-inline team-result p-b-0' id={`${side}-team-result`}>
            <div className='score-input-group display-inline-block m-b-1'>
                <div className={`input-group m-r-2 ${mainScoreHasError ? 'has-error' : 'has-success'}`}>
                    {mainScoreHasError
                        ? <span className="input-group-addon"
                            data-toggle='tooltip'
                            data-state='danger'
                            data-placement='top'
                            data-original-title='Проверьте количество голов в протоколе'>Гол</span>
                        : <span className="input-group-addon">Гол</span>}
                
                    <input type="number"
                        className="form-control input-sm team-score team-score-goals"
                        value={goal}
                        onChange={(event) => { setGoal(parseInt(event.target.value, 10)) }}
                        onBlur={scoreChangedHandler}
                    />
                </div>

                <div className={`input-group m-r-2 ${penaltyScoreHasError ? 'has-error' : null}`}>
                    {penaltyScoreHasError
                        ? <span className="input-group-addon"
                            data-toggle='tooltip'
                            data-state='danger'
                            data-placement='top'
                            data-original-title='Проверьте количество забитых пенальти в протоколе'>Пен</span>
                        : <span className="input-group-addon">Пен</span>}
                    
                    <input type="text"
                        className="form-control input-sm team-score team-score-pen"
                        value={penalty}
                        onChange={(event) => { setPenalty(getInputScoreValue(event.target.value)) }}
                        onBlur={scoreChangedHandler} />
                </div>
            </div>

            <div className="display-inline-block m-b-1">
                <div className="input-group m-r-2">
                    <span className="input-group-addon">Фол1</span>
                    <input type="text"
                        className="form-control input-sm team-score team-score-foul1"
                        value={foul1}
                        onChange={(event) => { setFoul1(getInputScoreValue(event.target.value)) }}
                        onBlur={scoreChangedHandler} />
                </div>

                <div className="input-group">
                    <span className="input-group-addon">Фол2</span>
                    <input type="text"
                        className="form-control input-sm team-score team-score-foul2"
                        value={foul2}
                        onChange={(event) => { setFoul2(getInputScoreValue(event.target.value)) }}
                        onBlur={scoreChangedHandler} />
                </div>
            </div>
        </div>
    }

    const teamDetails = () => {
        return <div className='list-group'>
            {getResultsRow()}
            {getPlayerList()}
        </div>
    }

    const noTeam = () => {
        return <div className='panel-body'>
         {I18n.t('matches.select_match')}
        </div>
    }

    const loadingClass = loading ? 'form-loading' : ''
    const classes = `panel ${loadingClass}`

    return <div className='col-md-6'>
        <div className={classes}>
        <div className="panel-heading">
            {panelTitle()}
        </div>
        
        {panelBody()}
        </div>
    </div>
}

export default Team
