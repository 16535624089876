const eventTypes = {
    GOAL: 'goal',
    GOAL_6M: 'goal_6m',
    GOAL_10M: 'goal_10m',
    YELLOW_CARD: 'yellow_card',
    RED_CARD: 'red_card',
    OWN_GOAL: 'own_goal',
    ASSIST: 'assist',
    MISSED_GOAL_6M: 'missed_goal_6m',
    MISSED_GOAL_10M: 'missed_goal_10m',
    PENALTY: 'penalty',
    MISSED_PENALTY: 'missed_penalty',
}

export default eventTypes
