import React from "react"
import BootstrapPanel from "../../general/BootstrapPanel"
import DatepickerBase from "../../general/DatepickerBase"
import TimepickerBase from "../../general/TimepickerBase"

class ScheduleTimeRangeForm extends React.Component {
  constructor () {
      super();
      this.handleStartTimeChange = this.handleStartTimeChange.bind(this)
  }

  handleStartTimeChange(event) {
      let startTime = event.time
      let endPicker = this.refs['slot-end-time']
      let endTime = endPicker.state.time

      // лучше делать флаг что endDate еще не задавалась и до тех пор всегда увеличивать ее на час
      if (!endTime.hours || this.timeDiff(startTime, endTime) < 60 && startTime.hours < 24) {
          endTime.hours = startTime.hours + 1
          endTime.minutes = startTime.minutes
          endPicker.setTime(endTime)
      }
  }

  handleSubmit(event) {
      console.log('SUBMITTED')
      event.preventDefault()
  }

  timeDiff(startTime, endTime) {
      if (!Number.isInteger(endTime.hours + endTime.minutes + startTime.hours + startTime.minutes)) {
          return -1
      }

      return (endTime.hours * 60 + endTime.minutes) - (startTime.hours * 60 + startTime.minutes)
  }

  render () {
    return <BootstrapPanel title={I18n.t('admin.schedule.adding_time_slots')}>
        <form action='#' onSubmit={this.handleSubmit}>
            <div className='row'>
                <div className='col-md-6 form-group'>
                    <label htmlFor='slot-date'>{I18n.t('general.date')}</label>
                    <DatepickerBase type='future' name='slot-date'/>
                </div>

                <div className='col-md-3 form-group'>
                    <label htmlFor='slot-start-time'>{I18n.t('general.start')}</label>
                    <TimepickerBase name='slot-start-time' onChange={this.handleStartTimeChange}/>
                </div>

                <div className='col-md-3 form-group'>
                    <label htmlFor='slot-end-time'>{I18n.t('general.end')}</label>
                    <TimepickerBase name='slot-end-time' ref='slot-end-time'/>
                </div>

                <div className='form-group col-md-12'>
                    <button type="submit" className="btn btn-primary">{I18n.t('buttons.add')}</button>
                </div>
            </div>
        </form>
    </BootstrapPanel>
  }
}

export default ScheduleTimeRangeForm
