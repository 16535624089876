import React, { useEffect, useState } from "react"
import ApiModule from "../../ApiModule";

const Tours = ({ season_id, tour_id, onTourClick, colClass }) => {
    const api = new ApiModule()
    const [tours, setTours] = useState([])

    useEffect(() => {
        getTours()
    }, [])

    const tourFullName = (tour) => {
        const reg = /.*\(.*\)/i
        const tourName = tour.name
        const stageName = tour.stage.name
        return stageName && !reg.test(tourName) ? `${tourName} (${stageName})` : tourName
    }

    const listGroup = () => {
        return tours.length > 0 ? tours.map(tourRow) : emptyList()
    }

    const emptyList = () => {
        return <div className='panel-body form-loading'>
            <span className='font-italic'>{I18n.t('tours.loading')}...</span>
        </div>
    }

    const getTours = () => {
        api.list('tours', null, `seasons/${season_id}`).then(setTours)
    }

    const tourRow = (tour) => {
        let classList = 'list-group-item'
        if (tour.id === tour_id) {
            classList += ' admin-list-group-item-selected font-weight-semibold text-default'
        }

        const attrs = {
            key: tour.id,
            className: classList,
            href: '#',
            onClick: onTourClick.bind(this, tour.id),
        }

        return <a { ...attrs }>
            {tourFullName(tour)}
        </a>
    }

    return <div className={colClass} id='tours-panel'>
        <div className="panel">
            <div className="panel-heading">
                <span className="panel-title">{I18n.t('tours.tours')}</span>
            </div>
            <div className='list-group compact-list-group'>
                {listGroup()}
            </div>
        </div>
    </div>
}

export default Tours
