import React, { useEffect, useRef, useState } from "react"
import ApiModule from "../../../ApiModule"
import { TIMELINE_EVENT_TYPES, TIMELINE_EVENT_UI } from './PlayerEvents'

const Player = ({ footballer, matchId, side, events = [], duplicatedNumbers, setSynchedCallback,
    handlePlayerAttendanceChanged, handleNumberChanged, onPlayerUpdate }) => {
    const api = new ApiModule()

    let initNumber = parseInt(footballer.number, 10)
    if (initNumber <= 0 || isNaN(initNumber)) {
        initNumber = ''
    }

    const [errorMessage, setErrorMessage] = useState(null)

    const rowHasError = () => {
        return hasNumberIssue() || hasEventsIssue() || hasDuplicateNumber()
    }

    const hasNumberIssue = () => {
        if (attendance && number === '') {
            setErrorMessage('Не указан номер игрока')
            return true
        }

        return false
    }

    const hasDuplicateNumber = () => {
        if (attendance && duplicatedNumbers.includes(parseInt(number, 10))) {
            setErrorMessage('Номер игрока дублирован')
            return true
        }

        return false
    }

    const hasEventsIssue = () => {
        if (!attendance && events.length > 0) {
            setErrorMessage('Игрок не заявлен на игру, но указан в протоколе')
            return true
        }

        return false
    }

    const tooltipRef = useRef(null)
    const [minute, setMinute] = useState('')
    const [minuteError, setMinuteError] = useState(false)
    const [attendance, setAttendance] = useState(!!footballer.attendance)
    const [number, setNumber] = useState(initNumber)
    const [lastNumber, setLastNumber] = useState(null)
    const [hasError, setHasError] = useState(false)
    
    useEffect(() => {
        setHasError(rowHasError())
    }, [number, attendance, duplicatedNumbers])

    useEffect(() => {
        $(tooltipRef.current).tooltip();
    }, [errorMessage])

    const isPenalty = (type) => ['penalty', 'missed_penalty'].includes(type)

    const toggleAttendance = (event) => {
        const attendance = event.target.checked
        setAttendance(attendance)
        handlePlayerAttendanceChanged(footballer, attendance)
    }

    const saveTimelineEvent = (event_type, event) => {
        let eventMinute = minute
        if (typeof eventMinute !== 'number') {
            if (isPenalty(event_type)) {
                setMinuteError(false)
                eventMinute = 0
            } else {
                setMinuteError(true)
                return false
            }
        }

        const data = {
            match_id: matchId,
            footballer_id: footballer.id,
            side,
            event_type,
            minute: eventMinute
        }

        setSynchedCallback(false)
        api.create('match_timelines', data)
            .then(() => {
                setMinute('')
                onPlayerUpdate()
            })
            .catch(error => growlError(error))
    }

    const removeTimeLineEvent = (eventId) => {
        setSynchedCallback(false)
        api.delete('match_timelines', eventId)
            .then(() => {
                onPlayerUpdate()
            })
            .catch(error => growlError(error))
    }

    const getPlayerName = () => {
        const name = `${footballer['last_name']} ${footballer['first_name']}`
        if (hasError) {
            return <span className='text-danger'
                ref={tooltipRef}
                data-toggle='tooltip'
                data-placement='top'
                data-state='danger'
                data-original-title={errorMessage}>
                
                {name}
            </span>
        } else {
            return <span>{name}</span>
        }
    }

    const setEventMinute = (event) => {
        const value = event.target.value
        if (value.length == 0) {
            setMinute('')
        } else {
            const mnt = parseInt(value, 10)
            if (!isNaN(mnt) && mnt >= 0 && mnt < 100) {
                setMinute(mnt)
            }
        }
        setMinuteError(false)
    }

    const savePlayerNumber = () => {
        const playerNumber = parseInt(number, 10)
        if (playerNumber <= 0 || isNaN(playerNumber)) {
            setNumber('')
        } else if (playerNumber !== lastNumber) {
            setLastNumber(playerNumber)
            handleNumberChanged(footballer, playerNumber)
        }
    }

    const getPlayerNumber = () => {
        return <div className='display-inline-block m-x-1'>
            <input type='number'
                min={1}
                max={99}
                className='player-number form-control input-sm'
                value={number}
                onChange={(event) => { setNumber(event.target.value) }}
                onBlur={savePlayerNumber} 
                />
        </div>
    }

    const growlError = (error) => {
        const growlErrorMessage = error && error.response && error.response.data && error.response.data.error
            || I18n.t('general.unknown_error')

        $.growl.error({
            title: I18n.t('general.not_saved'),
            message: growlErrorMessage
        });
    }

    const getStatButton = () => {
        const minuteErrorClass = minuteError ? 'minute-error' : ''
        return <div className="input-group input-group-sm player-event-adder">
            <input type="text" value={minute} onChange={setEventMinute} className={`form-control stat-minute border-success ${minuteErrorClass}`} />
            {/* <small className="form-message dark">Some error message.</small> */}
            <div className="input-group-btn display-inline-block">
                <button type="button" className="btn btn-outline btn-success" onClick={saveTimelineEvent.bind(this, TIMELINE_EVENT_TYPES.GOAL.type)}>Гол</button>
                <button type="button" className="btn btn-outline btn-success dropdown-toggle" data-toggle="dropdown"></button>
                <ul className="dropdown-menu dropdown-menu-right dropdown-event-list">
                    {Object.values(TIMELINE_EVENT_TYPES).map(item => (<li key={item.type}>
                        <a href="#" onClick={saveTimelineEvent.bind(this, item.type)}>
                            <i className={item.icon}></i>
                            <span className='m-l-1'>{item.label}</span>
                        </a>
                    </li>))}
                </ul>
            </div>
        </div>
    }

    const getPlayerEvents = () => {
        return events.sort((a, b) => (a.minute - b.minute))
            .map(event => (
                <div key={event.id} className="tag label label-player-event">
                    <i className={TIMELINE_EVENT_UI[event.event_type]}></i>
                    {isPenalty(event.event_type)
                        ? null
                        : <span className='player-event-minute'>{event.minute}</span>
                    }
                    <button className="player-event-remover" onClick={removeTimeLineEvent.bind(this, event.id)}>×</button>
                </div>
            ))
    }

    let classList = 'list-group-item player-container'
    if (attendance) {
        classList += ' player-attendance-true'
    }

    if (hasError) {
        classList += ' player-error'
    }

    return <div className={classList}>
        <div className='float-left m-r-1'>
            <label className="custom-control custom-checkbox custom-control-blank checkbox-inline">
                <input type="checkbox"
                    className="custom-control-input"
                    defaultChecked={footballer.attendance}
                    onChange={toggleAttendance} />
                <span className="custom-control-indicator" />
            </label>

            {getPlayerNumber()}
            {getPlayerName()}
        </div>

        <div className='float-right'>
            {getStatButton()}
        </div>

        <div className='player-events-container'>
            {getPlayerEvents()}
        </div>
    </div>
}

export default Player
