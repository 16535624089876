import React from "react"
class DatepickerBase extends React.Component {
  constructor (props) {
      super();
      this.additionalClass = props.type
      this.name = props.name
      if (this.additionalClass.length > 0) {
          this.additionalClass = 'datepicker-' + this.additionalClass
      }
  }

  render () {
      return <input type="text" className={`form-control datepicker-base ${this.additionalClass}`}
                    id={this.name} name={this.name}/>
  }
}

export default DatepickerBase
