import React from "react"
import eventIconMap from "../EventIconsMap"
import eventsMap from "../EventsMap"

const Event = ({ event, player, assist, score }) => {
    const { GOAL, GOAL_6M, GOAL_10M, OWN_GOAL, 
        YELLOW_CARD, RED_CARD, MISSED_GOAL_6M, MISSED_GOAL_10M } = eventsMap

    const getMessage = () => {
        let message
        let assistMessage

        switch (event.type) {
            case GOAL:
                message = I18n.t('matches.timeline.goal')
                break
            case OWN_GOAL:
                message = I18n.t('matches.timeline.auto_goal')
                break
            case YELLOW_CARD:
                message = I18n.t('matches.timeline.yellow_card')
                break
            case RED_CARD:
                message = I18n.t('matches.timeline.red_card')
                break
            case GOAL_6M:
                message = I18n.t('matches.timeline.goal_6')
                break
            case GOAL_10M:
                message = I18n.t('matches.timeline.goal_10')
                break
            case MISSED_GOAL_6M:
                message = I18n.t('matches.timeline.missed_goal_6')
                break
            case MISSED_GOAL_10M:
                message = I18n.t('matches.timeline.missed_goal_10')
                break
            default:
                message = null
        }

        if (message) {
            const name = [player.last_name, player.first_name].join(' ')
            message = <>{name} <span dangerouslySetInnerHTML={{ __html: message }} /></>
        }

        if (assist) {
            const name = [assist.last_name, assist.first_name].join(' ')
            assistMessage = `${I18n.t('matches.timeline.assist')} ${name}`
        }

        return <>
            <div>{message}</div>
            {assistMessage
                ? <div>{assistMessage}</div>
                : null
            }
        </>
    }
    
    let classList = 'item'
    if (event.side === 'guest') {
        classList += ' right'
    }
    const eventMessage = getMessage()
    if (!eventMessage) {
        return null
    }
    
    return <div className={classList}>
        <div className="item-holder">
            <span className={eventIconMap[event.type]}></span>
            <div>{eventMessage}</div>
            {score ? <div>{I18n.t("matches.timeline.result")} {score}</div> : null}
        </div>
        <span className="minute">{event.minute}’</span>
    </div>

}

export default Event
