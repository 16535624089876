import React from "react"

class NewRow extends React.Component {

    constructor() {
        super();
    }

    render () {
        const createButton = <button className='btn btn-success btn-xs' onClick={this.props.handleCreateOnClick}>
            {I18n.t('buttons.create')}
        </button>
        const cancelButton = <button className='btn btn-default btn-xs m-l-1' onClick={this.props.handleCancelOnClick}>
            {I18n.t('buttons.cancel')}
        </button>

        return <tr id='new-season' className='success'>
            <td>
                <input type="text" className="form-control input-sm m-b-0"
                       name='season-name'
                       placeholder={I18n.t('general.title')}/>
            </td>
            <td className='hidden-xs'>
                <input type="text" className="form-control input-sm m-b-0"
                       name='season-description'
                       placeholder={I18n.t('general.title')}/>
            </td>
            <td>
                <label className="custom-control custom-checkbox" htmlFor="status">
                    <input type="checkbox"
                           id="status"
                           name='season-status'
                           className="custom-control-input"/>
                    <span className="custom-control-indicator"/>
                    {I18n.t('general.active')}
                </label>
            </td>
            <td className='fit'>
                <div className='pull-right'>
                    {createButton}
                    {cancelButton}
                </div>
            </td>
        </tr>
    }

}

export default NewRow
