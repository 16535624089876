import React from "react"
import TeamLineups from "./TeamLineUps"

const Lineups = ({ data, teamEvents }) => {
    return <div className='teams-info'>
        <TeamLineups data={data.host_team} teamEvents={teamEvents.host}/>
        <TeamLineups data={data.guest_team} teamEvents={teamEvents.guest}/>
    </div>
}

export default Lineups
