import React from "react"
import Event from "./Event"
import eventsMap from "../EventsMap"

const Timeline = ({ data, timeline, players }) => {
    if (timeline.length === 0) {
        return null
    }

    const { GOAL, GOAL_6M, GOAL_10M, OWN_GOAL } = eventsMap
    const score = { host: 0, guest: 0 }

    const getTeamsTimeFouls = (time) => {
        const time_key = time === 1 ? 'first_period_fouls' : 'second_period_fouls'
        return <div>
            {data.host_team.name} - {data.host_team[time_key] || 0},&nbsp;
            {data.guest_team.name} - {data.guest_team[time_key] || 0}
        </div>
    }

    const getFoulsBlock = (time) => {
        if (time !== 1 && time !== 2) {
            return null
        }

        return <div key={`fouls-${time}`} className='text'>
            <div>{I18n.t('matches.timeline.fouls', { time })}</div>
            {getTeamsTimeFouls(time)}
        </div>
    }

    const getLines = () => {
        const lines = []
        timeline.forEach(event => {
            let showScore = false
            if (time === 1 && event.minute > 25) {
                lines.push(getFoulsBlock(1))
                time = 2
            }

            if ([GOAL, GOAL_6M, GOAL_10M].includes(event.type)) {
                score[event.side]++
                showScore = true
            }

            if (event.type === OWN_GOAL) {
                score[event.side]--
                score.host++
                score.guest++
                showScore = true
            }

            lines.push(<Event
                key={event.id} 
                event={event}
                player={players[event.player_id]}
                assist={event.assist && players[event.assist.footballer_id]}
                score={showScore && `${score.host}:${score.guest}`} />)
        })

        lines.push(getFoulsBlock(2))

        return lines;
    }

    let time = 1

    return <div className='match-timeline'>
        <div className='text'>{I18n.t('matches.begin')}</div>
        {getLines()}
    </div>
}

export default Timeline
