import React from "react"
import moment from 'moment'
import Game from "./Game";
import NewGame from "./NewGame";

class Day extends React.Component {
    constructor () {
        super();
    }

    setNewGameValue () {
        const lastGame = this.props.games.slice(-1)[0]
        const lastMatchAt = moment(lastGame['match-at'], 'HH:mm')
        this.lastTour = lastGame.tour.id
        const newSlotStart = lastMatchAt.hours() < 22 ? lastMatchAt.add(1, 'hours').format('HH:mm') : ''
        this.state = {
            newSlotStart: newSlotStart
        }
    }

    render () {
        this.setNewGameValue()
        let headingClass = this.props.past ? 'panel-heading bg-default' : 'panel-heading'
        return <div className='panel'>
            <div className={headingClass}>
                <div className="panel-title">{this.props.dayTitle}</div>
            </div>
            <div className='table-responsive'>
                <table className='table'>
                    <thead>
                    <tr>
                        <th>{I18n.t('th.time')}</th>
                        <th>{I18n.t('th.venue')}</th>
                        <th>{I18n.t('th.tour')}</th>
                        <th>{I18n.t('th.host')}</th>
                        <th>{I18n.t('th.guest')}</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                        {this.props.games.map(game =>
                            <Game key={game.id}
                                  game={game}
                                  teams={this.props.teams}
                                  tours={this.props.tours}
                                  venues={this.props.venues}
                                  onEditClick={this.props.onEditClick}
                                  onRemoveClick={this.props.onRemoveClick}
                                  onSaveClick={this.props.onSaveClick}
                                  onCancelEditClick={this.props.onCancelEditClick}
                                  inEdit={!!this.props.inEdit[game.id]}
                                  inDeleting={!!this.props.inDeleting[game.id]}/>
                        )}

                        <NewGame game={{}}
                                 date={this.props.date}
                                 startTime={this.state.newSlotStart}
                                 teams={this.props.teams}
                                 tours={this.props.tours}
                                 venues={this.props.venues}
                                 lastTour={this.lastTour}
                                 onNewSlotSubmit={this.props.onNewSlotSubmit}/>
                    </tbody>
                </table>
            </div>
        </div>
    }
}

export default Day
