const TIMELINE_EVENT_TYPES = {
  GOAL: {
    type: 'goal',
    label: 'Гол',
    icon: 'fa fa-futbol-o'
  },

  ASSIST: {
    type: 'assist',
    label: 'Асисит',
    icon: 'fa fa-handshake-o'
  },
  YELLOW_CARD: {
    type: 'yellow_card',
    label: 'Желтая карточка',
    icon: 'event-icon icon-yellow-card event-icon-card'
  },
  RED_CARD: {
    type: 'red_card',
    label: 'Красная карточка',
    icon: 'event-icon icon-red-card event-icon-card'
  },
  GOAL_6M: {
    type: 'goal_6m',
    label: '6-метровый, гол',
    icon: 'fa fa-product-hunt'
  },
  GOAL_10M: {
    type: 'goal_10m',
    label: '10-метровый, гол',
    icon: 'fa fa-arrow-right'
  },
  OWN_GOAL: {
    type: 'own_goal',
    label: 'Автогол',
    icon: 'fa fa-futbol-o missed'
  },
  MISSED_GOAL_6M: {
    type: 'missed_goal_6m',
    label: '6-метровый (не забит)',
    icon: 'fa fa-product-hunt missed'
  },
  MISSED_GOAL_10M: {
    type: 'missed_goal_10m',
    label: '10-метровый (не забит)',
    icon: 'fa fa-arrow-right missed'
  },
  PENALTY: {
    type: 'penalty',
    label: 'Послематчевый пенальти, гол',
    icon: 'fa fa-circle text-success'
  },
  MISSED_PENALTY: {
    type: 'missed_penalty',
    label: 'Послематчевый пенальти (не забит)',
    icon: 'fa fa-circle missed'
  },
}

const GOAL_TYPES = [
    TIMELINE_EVENT_TYPES.GOAL.type,
    TIMELINE_EVENT_TYPES.GOAL_6M.type,
    TIMELINE_EVENT_TYPES.GOAL_10M.type
]

const TIMELINE_EVENT_UI = {}
Object.values(TIMELINE_EVENT_TYPES).forEach(item => {
  TIMELINE_EVENT_UI[item.type] = item.icon
})

export { TIMELINE_EVENT_TYPES, TIMELINE_EVENT_UI, GOAL_TYPES }
