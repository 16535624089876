import React from "react"
import Event from "./Event"

const Penalty = ({ penalties, players }) => {
    if (penalties.host.length + penalties.guest.length === 0) {
        return null
    }

    return <div className='match-block'>
        <h3>{I18n.t('matches.penalty')}</h3>
        <div className='block-holder'>
            <Event events={penalties.host} players={players} showMinute={false} />
            <Event events={penalties.guest} players={players} showMinute={false} />
        </div>
    </div>
}

export default Penalty
