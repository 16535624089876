let tour = null
let match = null

const refreshHash = () => {
    tour = tour || navigator.getTour()

    if (tour) {
        window.location.hash = match ? `${tour}/${match}` : tour
    }
}

const navigator = {
    setTour(t) {
        match = null
        tour = t
        refreshHash()
    },

    setMatch(m) {
        match = m
        refreshHash()
    },

    getTour() {
        const hash = window.location.hash.substr(1)
        return hash.split('/')[0]
    },

    getMatch() {
        const hash = window.location.hash.substr(1)
        return hash.split('/')[1]
    },
}

export default navigator