import React, { useEffect, useState } from "react"
import ApiModule from "../../ApiModule";
import Tours from "./Tours"
import Matches from "./Matches"
import Game from "./Game";
import navigator from "./Navigator";

const Protocols = ({ season_id }) => {
    const api = new ApiModule()
    const initTourId = navigator.getTour()
    const initMatchId = navigator.getMatch()

    const [matches, setMatches] = useState([])
    const [tourId, setTourId] = useState(initTourId)
    const [matchId, setMatchId] = useState(initMatchId)
    const [scheduleId, setScheduleId] = useState(null)
    const [expanded, setExpanded] = useState(false)

    const [matchListLoading, setMatchListLoading] = useState(false)
    const [matchDetailsLoading, setMatchDetailsLoading] = useState(false)
    
    useEffect(() => {
        initTourId && handleTourOnClick(initTourId)
        initMatchId && handleMatchOnClick(initMatchId)
    }, [])

    const handleTourOnClick = (tourId, event) => {
        if (event) {
            event.preventDefault()
            navigator.setTour(tourId)
        }

        setTourId(tourId)
        setMatchId(null)
        setScheduleId(null)
        setExpanded(false)
        setMatchListLoading(true)
        getTourMatches(tourId)
    }

    const handleMatchOnClick = (matchId, event) => {
        if (event) {
            event.preventDefault()
            navigator.setMatch(matchId)
        }

        setMatchId(matchId)
        setExpanded(false)
        setMatchDetailsLoading(true)
    }

    const handleMatchOnLoaded = (scheduleId) => {
        setScheduleId(scheduleId)
    }

    const handleExpandClick = (event) => {
        event.preventDefault()
        setExpanded(!expanded)
    }

    const handleScoreUpdate = (team_id, side, score, onUpdateCallback) => {
        const onSaveScheduleId = scheduleId

        const data = { team_id, side, score }
        // the second parameter for url is bad
        const dummyId = 123456
        const url = Routes.admin_api_v1_schedule_score_path(scheduleId, dummyId)
        api.updateItem(url, data).then(response => {
            const matchesToUpdate = [ ...matches ]

            // ищем нужный матч в общем списке и обновляем его
            for(let i = 0; i < matchesToUpdate.length; i++) {
                const match = matchesToUpdate[i]
                if (match.id == onSaveScheduleId) {
                    if (side === 'host') {
                        setScoreDetails(match['host-points'], score)
                    } else {
                        setScoreDetails(match['guest-points'], score)
                    }
                    break
                }
            }

            setMatches(matchesToUpdate)

            onUpdateCallback && onUpdateCallback()
        })
    }

    const setScoreDetails = (scoreDetails, newScore) => {
        for (let score in newScore) {
            if (newScore.hasOwnProperty(score)) {
                scoreDetails[score] = newScore[score]
            }
        }
    }

    const getTourMatches = (tourId) => {
        if (!tourId) {
            return
        }
        const inclusions = ['hosts', 'guests', 'tour', 'league', 'venue', 'host_points', 'guest_points', 'match']
        api.list('schedules', inclusions, `tours/${tourId}`)
            .then(data => {
                setMatches(data)
                setMatchListLoading(false)
            })
    }

    return <div className='row'>
        <Tours
            season_id={season_id}
            tour_id={tourId}
            colClass={expanded ? 'col-md-1' : 'col-md-2'}
            onTourClick={handleTourOnClick}/>

        <Matches
            tour_id={tourId}
            selectedMatchId={matchId}
            matches={matches}
            loading={matchListLoading}
            colClass={expanded ? 'col-md-1' : 'col-md-2'}
            onMatchClick={handleMatchOnClick}/>

        <Game
            scheduleId={scheduleId}
            matchId={matchId}
            onMatchLoaded={handleMatchOnLoaded}

            colClass={expanded ? 'col-md-10' : 'col-md-8'}
            loading={matchDetailsLoading}

            onExpandClick={handleExpandClick}
            onScoreUpdate={handleScoreUpdate}/>
    </div>
}

export default Protocols
