import React from "react"

class StageContent extends React.Component {

    nodataContent() {
        return <div className="panel-body p-y-1">
            {I18n.t('admin.stages.choose_stage')}
        </div>
    }

    loadingStateContent() {
        return <div className="panel-body p-y-1">
            <span className='font-italic'>
                {I18n.t('general.loading')}
            </span>
        </div>
    }

    getTable() {
        const className = `table table-bordered table-striped table-sortable m-b-0 table-sortable-${this.props.type}`
        return <div className='table'>
            <table className={className}>
                <tbody>
                    {this.props.getList()}
                    {this.props.newItemRow}
                </tbody>
            </table>
        </div>
    }

    getPanelContent() {
        let content
        if (this.props.stageId > 0) {
            if (this.state.list === null) {
                content = this.loadingStateContent()
            } else {
                content = this.getTable()
            }
        } else {
            content = this.nodataContent()
        }

        return content
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            list: nextProps.list
        })
    }

    render () {
        return <div className="panel">
            <div className="panel-heading">
                <span className="panel-title font-weight-semibold">
                    {this.props.title}
                </span>
            </div>
            {this.getPanelContent()}
        </div>
    }

}

export default StageContent
