import React from "react"

class Result extends React.Component {

    constructor() {
        super()
    }

    resultsTable() {
        const host = this.props.hostDetails
        const guest = this.props.guestDetails
        if (!host) {
            return null
        }

        return <table className='table table-bordered m-b-0'>
            <thead>
                <tr>
                    <td/>
                    <td>Голы</td>
                    <td>Пен</td>
                    <td>Фолы 1т</td>
                    <td>Фолы 2т</td>
                </tr>
            </thead>
            <tbody>
                {this.teamResults(host)}
                {this.teamResults(guest)}
            </tbody>
        </table>
    }

    teamResults(details) {
        return <tr>
            <td className='text-right'>{details.team.name}</td>
            <td>
                <input type='text' className='form-control input-sm team-score' defaultValue={details.score}/>
            </td>
            <td>
                <input type='text' className='form-control input-sm team-score' defaultValue='-'/>
            </td>
            <td>
                <input type='text' className='form-control input-sm team-score' defaultValue='-'/>
            </td>
            <td>
                <input type='text' className='form-control input-sm team-score' defaultValue='-'/>
            </td>
        </tr>
    }

    render () {
        return <div className="panel">
            <div className="panel-heading">
                <span className="panel-title">{I18n.t('protocols.results')}</span>
            </div>
            <div className="panel-body">
                {this.resultsTable()}
            </div>
        </div>
    }
}

export default Result
