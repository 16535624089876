import React from "react"
import IconButon from "../../general/IconButon"

class ViewRow extends React.Component {

    constructor(props) {
        super();
        this.data = props.data
    }

    activityLabel(playoff) {
        return playoff
            ? <span className='label label-default'>{I18n.t('admin.stages.playoff')}</span>
            : <span></span>
    }

    render () {
        const id = this.data.id
        const editButton = <IconButon icon='pencil' type='primary' outline={true} handleOnClick={this.props.handleEditOnClick.bind(this, id)}/>
        const deleteButton = <IconButon icon='trash' type='danger' outline={true} handleOnClick={this.props.handleDeleteOnClick.bind(this, id)}/>

        let rowClass
        switch (id) {
           case this.props.toDeleteRow:
               rowClass = 'danger'
               break
            case this.props.selectedRow:
                rowClass = 'info font-weight-semibold'
                break
            default:
                rowClass = ''
       }

        return <tr data-id={id} className={rowClass}>
            <td>
                <i className="fa fa-icon icon-move m-r-1 fa-arrows text-light sortable-icon"/>
                <a href='#' className='text-default'
                   onClick={this.props.handleSelectOnClick.bind(this, id)}>
                    {this.data.name}
                </a>
            </td>
            <td>{this.activityLabel(this.data.playoff)}</td>
            <td className='fit text-center'>
                {editButton}
                &nbsp;&nbsp;
                {deleteButton}
            </td>
        </tr>
    }

}

export default ViewRow
