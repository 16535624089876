import React from "react"

class ViewRow extends React.Component {

    constructor(props) {
        super();
        this.data = props.data
    }

    activityLabel(active) {
        return active
            ? <span className='label label-success'>{I18n.t('general.active')}</span>
            : <span className='label label-danger'>{I18n.t('general.finished')}</span>
    }

    render () {
        const editButton = <button className='btn btn-primary btn-xs' onClick={this.props.handleEditOnClick.bind(this, this.data.id)}>
            {I18n.t('buttons.edit_short')}
        </button>

        return <tr>
            <td>{this.data.name}</td>
            <td className='hidden-xs'>{this.data.description}</td>
            <td>{this.activityLabel(this.data.active)}</td>
            <td className='fit'>
                <div className='pull-right'>
                    {editButton}
                </div>
            </td>
        </tr>
    }

}

export default ViewRow
