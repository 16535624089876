import React from "react"

const TeamLineups = ({ data = { players: [] }, teamEvents }) => {
    const getPlayerLine = (player) => {
        const number = !player.number || player.number < 0 ? '#' : player.number
        const name = [player.last_name || '', player.first_name || ''].join(' ')
        return <li key={player.id}>{number}. {name} {composePlayerEvents(player)}</li>
    }

    const composePlayerEvents = (player) => {
        
        const playerEvents = teamEvents && teamEvents[player.id]
        if (!playerEvents) {
            return null
        }

        let events = []
        events = [...events, getPlayerGoals(playerEvents)]
        events = [...events, getPlayerOwnGoals(playerEvents)]
        events = [...events, getPlayerYellowCards(playerEvents)]
        events = [...events, getPlayerRedCards(playerEvents)]

        return events.length == 0 ? null : <span className='player-events'>{events}</span>
    }
    const getPlayerGoals = (playerEvents) => getPlayerEvents(playerEvents, 'goal', 'icon-ball')
    const getPlayerOwnGoals = (playerEvents) => getPlayerEvents(playerEvents, 'own_goal', 'icon-ball missed')
    const getPlayerYellowCards = (playerEvents) => getPlayerEvents(playerEvents, 'yellow_card', 'icon-card')
    const getPlayerRedCards = (playerEvents) => getPlayerEvents(playerEvents, 'red_card', 'icon-card red')

    const getPlayerEvents = (playerEvents, type, icon) => {
        const times = playerEvents[type] || 0
        const events = []

        for (let i = 0; i < times; i++){
            events.push(<span key={i} className={icon}></span>)
        }

        return events
    }

    const getPlayerList = (playerList) => {
        return <ul className='players-list'>
            {playerList.map(player => getPlayerLine(player))}
        </ul>
    }

    const playerList = data.players.filter(player => player.attendance)

    return <div className='content-box widget'>
        <h3>{data.name || '-'}</h3>
        {playerList.length > 0 
            ? getPlayerList(playerList)
            : <div className='no-data'>Нет данных</div>
        }
    </div>
}

export default TeamLineups
