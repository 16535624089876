import React from "react"
import FaIcon from "./FaIcon"
class IconButon extends React.Component {

    constructor(props) {
        super();
        const type = props.type || 'default'
        const outline = props.outline === undefined ? false : props.outline === true
        const outLineClass = outline ? 'btn-outline' : ''

        this.buttonClassName = `btn ${outLineClass} btn-xs btn-${type}`
    }

    render () {
        return <button className={this.buttonClassName} onClick={this.props.handleOnClick}>
            <FaIcon icon={this.props.icon}/>
        </button>
    }
}

export default IconButon
