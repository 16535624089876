import React from "react"
import ApiModule from "../../../ApiModule"
import ViewRow from "./ViewRow"
import EditRow from "./EditRow"
import NewRow from "./NewRow"
import StageContent from "../StageContent"
import NoDataRow from "../../../general/NoDataRow"

class Tours extends React.Component {

    constructor() {
        super();
        this.api = new ApiModule()
        this.state = {
            list: [],
            editRow: -1,
            toDeleteRow: -1
        }

        this.handleCreateOnClick = this.handleCreateOnClick.bind(this)
        this.handleEditOnClick = this.handleEditOnClick.bind(this)
        this.handleSaveOnClick = this.handleSaveOnClick.bind(this)
        this.handleCancelOnClick = this.handleCancelOnClick.bind(this)
        this.handleDeleteOnClick = this.handleDeleteOnClick.bind(this)
        this.getTourList = this.getTourList.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            list: nextProps.list
        })
    }

    componentDidUpdate() {
        this.props.initSortable('tours')
    }

    handleCreateOnClick() {
        const row = $(`#new-tour`)
        const tour = {
            name: row.find("[name='tour-name']").val(),
        }

        const url = Routes.admin_api_v1_stage_tours_path(this.props.stageId)
        this.api.createItem(url, tour)
            .then(data => {
                row.find("[name='tour-name']").val('')

                this.setState(prevState => {
                    let list = prevState.list
                    list.push({
                        id: Number.parseInt(data.id, 10),
                        name: data.name,
                    })
                    return {
                        list: list
                    }
                })

                $.growl.success({
                    title: I18n.t('tours.tour_created'),
                    message: tour.name});
            })
            .catch(e => {
                const error = e && e.response && e.response.data && e.response.data.error
                    || I18n.t('general.unknown_error')

                $.growl.error({
                    title: I18n.t('general.not_saved'),
                    message: error});
            })
    }

    handleEditOnClick(id) {
        this.setState({
            editRow: id
        })
    }

    handleSaveOnClick(id) {
        const row = $(`#tour-${id}`)
        const tour = {
            name: row.find("[name='tour-name']").val(),
        }

        const url = Routes.admin_api_v1_stage_tour_path(this.props.stageId, id)
        this.api.updateItem(url, tour)
            .then(data => {
                this.setState(prevState => {
                    let list = prevState.list
                    list.forEach(item => {
                        if (Number.parseInt(item.id, 10) === Number.parseInt(data.id, 10)) {
                            item.name = data.name
                        }
                    })
                    return {
                        editRow: -1,
                        list: list
                    }
                })

                $.growl.success({
                    title: I18n.t('tours.tour_saved'),
                    message: tour.name});

                this.focusNewInput()
            })
            .catch(e => {
                const error = e && e.response && e.response.data && e.response.data.error
                    || I18n.t('general.unknown_error')

                $.growl.error({
                    title: I18n.t('general.not_saved'),
                    message: error});
            })
    }

    handleCancelOnClick() {
        this.setState({
            editRow: -1
        })
    }

    handleDeleteOnClick(id) {
        this.setState({
            toDeleteRow: id
        })

        let index
        let name
        const list = this.state.list
        for (let i in list) {
            if (list[i].id === id) {
                index = i
                name = list[i].name
                break
            }
        }

        setTimeout(() => {
            const cfrm = `${I18n.t('general.delete')} ${I18n.t('tours.tour')}: ${name}?`
            if (confirm(cfrm)) {
                const url = Routes.admin_api_v1_stage_tour_path(this.props.stageId, id)
                this.api.deleteItem(url)
                    .then(data => {
                        const error = this.responseError(data)
                        if (error) {
                            $.growl.error({
                                title: I18n.t('tours.not_deleted'),
                                message: error
                            })
                        } else {
                            this.setState(prevState => {
                                let list = prevState.list
                                list.splice(index, 1)
                                return {
                                    list: list,
                                    toDeleteRow: -1
                                }
                            })

                            $.growl.success({
                                title: I18n.t('tours.tour_deleted'),
                                message: name
                            });
                        }

                        this.setState({
                            toDeleteRow: -1
                        })
                        this.focusNewInput()
                    })
            } else {
                this.setState({
                    toDeleteRow: -1
                })
            }
        }, 1)

    }

    focusNewInput() {
        $('#new-tour input').focus()
    }

    responseError(data) {
        return data && data.data && data.data.error || false
    }

    getTourList() {
        const list = []
        this.state.list.forEach(tour => {
            let row
            if (tour.id === this.state.editRow) {
                row = <EditRow key={tour.id}
                               handleSaveOnClick={this.handleSaveOnClick}
                               handleCancelOnClick={this.handleCancelOnClick}
                               data={tour}/>
            } else {
                row = <ViewRow key={tour.id}
                               handleEditOnClick={this.handleEditOnClick}
                               handleDeleteOnClick={this.handleDeleteOnClick}
                               toDeleteRow={this.state.toDeleteRow}
                               data={tour}/>
            }

            list.push(row)
        })

        if (list.length === 0) {
            list.push(<NoDataRow key={1} colspan={2} text={I18n.t('tours.no_tours')}/>)
        }
        return list
    }

    render () {
        const newRow = <NewRow handleCreateOnClick={this.handleCreateOnClick}/>
        return <StageContent type='tours'
                             title={I18n.t('tours.tours')}
                             stageId={this.props.stageId}
                             list={this.state.list}
                             getList={this.getTourList}
                             newItemRow={newRow}/>
    }

}

export default Tours
