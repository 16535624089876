import React from "react"
// import PropTypes from "prop-types"
class AdminLeftMenu extends React.Component {
  render () {
    return <nav className="px-nav px-nav-left px-nav-animate">
        <div className="px-nav-scrollable-area">
            <ul className="px-nav-content ps-container ps-theme-default">
                <li className="px-nav-item">
                    <a href="#">
                        <i className="px-nav-icon fa fa-icon fa-trophy"/>
                        <span className="px-nav-label">Сезоны</span>
                    </a>
                </li>

                <li className="px-nav-item">
                    <a href={this.props.path.schedule}>
                        <i className="px-nav-icon fa fa-icon fa-calendar"/>
                        <span className="px-nav-label">Расписание</span>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
  }
}

export default AdminLeftMenu
