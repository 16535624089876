import React from "react"
class TimepickerBase extends React.Component {
    constructor() {
        super();
        this.state = {
            time: {
                hours: null,
                minutes: null
            }}
    }

    componentDidMount() {
        let onChange = this.props.onChange
        let picker = $(this.refs[this.props.name])

        picker.timepicker({
            use24hours: true,
            showMeridian: false
        }).on('changeTime.timepicker', (e) => {
            let time = e.time
            this.setState({
                time: {
                    hours: time.hours,
                    minutes: time.minutes
                }
            })
            if (onChange) {
                onChange(e)
            }
        })

        let time = picker.val()
        if (time) {
            this.setState({
                time: {
                    // value: time
                }
            })
        }
    }

    setTime(time) {
        let picker = $(this.refs[this.props.name])
        picker.timepicker('setTime', `${time.hours}:${time.minutes} AM`);
    }

    render () {
        return <input type="text" className='form-control timepicker-base'
                      id={this.props.name} name={this.props.name} ref={this.props.name}/>
    }
}

export default TimepickerBase
