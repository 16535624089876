import React from "react"
import FaIcon from "./general/FaIcon";
class PageHeader extends React.Component {
  render () {
    return <div className='page-header'>
        <h1>
            <FaIcon icon={this.props.icon}/>
            <span className='m-l-1'>{this.props.title}</span>
        </h1>
        <span className='pull-right'>{this.props.rightText}</span>
    </div>
  }
}

export default PageHeader
