import React from "react"

const AdditionalInfo = ({ event }) => {
    const additionalInfo = {
        goal_6m: '(п)',
        goal_10m: '(10м)'
    }

    const info = additionalInfo[event.event_type]

    return info
        ? <span>{info}</span> 
        : null
}

export default AdditionalInfo
