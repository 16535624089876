import axios from "axios/index";
const Presenter = require('yayson')({adapter: 'default'}).Presenter
const Store = require('yayson')().Store

const API_ADMIN_BASE_URL = '/admin/api/v1'
const API_BASE_URL = '/api/v1'
const CSRF_TOKEN = $("meta[name='csrf-token']").attr('content')
const AXIOS_OPTIONS = {
    headers: { 'X-CSRF-Token': CSRF_TOKEN }
}

const store = new Store()

class ApiModule {
    #baseUrl

    constructor(adminMode = true) {
        this.#baseUrl = adminMode ? API_ADMIN_BASE_URL : API_BASE_URL
    }

    list(resource, inclusions, parent) {
        const url = this.#listUrl(resource, inclusions, parent)
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        })
    }

    get(resource, id, inclusions) {
        let url = this.#getUrl(resource, id)
        url = this.#addInclusions(url, inclusions)
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        })
    }

    getList(url, inclusions) {
        url = this.#addInclusions(url, inclusions)
        return new Promise((resolve, reject) => {
            axios.get(url)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        })
    }

    create(resource, data) {
        return new Promise((resolve, reject) => {
            const presenter = new Presenter()
            presenter.type = resource
            const json = presenter.render(data)

            axios.post(this.#postUrl(resource), json, AXIOS_OPTIONS)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        });
    }

    createItem(url, data) {
        return new Promise((resolve, reject) => {
            const presenter = new Presenter()
            presenter.type = this.#recognizeResource(url)
            const json = presenter.render(data)

            axios.post(url, json, AXIOS_OPTIONS)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        });
    }

    update(resource, id, data) {
        return new Promise((resolve, reject) => {
            const presenter = new Presenter()
            presenter.type = resource
            const json = presenter.render(data)
            axios.patch(this.#updateUrl(resource, id), json, AXIOS_OPTIONS)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        });
    }

    updateItem(url, data) {
        return new Promise((resolve, reject) => {
            const presenter = new Presenter()
            presenter.type = this.#recognizeResource(url)
            const json = presenter.render(data)
            axios.patch(url, json, AXIOS_OPTIONS)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        });
    }

    send(path, data, resource, method) {
        method = method || 'post'
        return new Promise((resolve, reject) => {
            const presenter = new Presenter()
            presenter.type = resource
            const json = presenter.render(data)
            axios[method](path, json, AXIOS_OPTIONS)
                .then(response => resolve(this.#composeData(response)))
                .catch(reject)
        });
    }

    delete(resource, id) {
        return axios.delete(this.#updateUrl(resource, id), AXIOS_OPTIONS)
    }

    deleteItem(url) {
        return axios.delete(url, AXIOS_OPTIONS)
    }

    // private 

    #listUrl(resource, inclusions, parent) {
        let path = parent ? `/${parent}` : ''
        path += `/${resource}`
    
        const delim = resource.indexOf('?') === -1 ? '?' : '&'
        const include = inclusions ? `${delim}include=${inclusions.join(',')}` : ''
        // const baseUrl = adminMode ? API_ADMIN_BASE_URL : API_BASE_URL
        // console.log(adminMode)
        return `${this.#baseUrl}${path}${include}`
    }
    
    #addInclusions(url, inclusions) {
        const include = inclusions ? `?include=${inclusions.join(',')}` : ''
        return `${url}${include}`
    }
    
    #updateUrl(resource, id) {
        return `${this.#baseUrl}/${resource}/${id}`
    }
    
    #getUrl(resource, id) {
        return `${this.#baseUrl}/${resource}/${id}`
    }
    
    #postUrl(resource) {
        return `${this.#baseUrl}/${resource}`
    }
    
    #pathUrl(resource, path) {
        return `${this.#baseUrl}/${resource}/${path}`
    }
    
    #recognizeResource(url) {
        const parts = url.split('/').reverse()
        for (const i in parts) {
            const part = parts[i]
            if (isNaN(part)) {
                return part
            }
        }
        return 'object'
    }

    #composeData = (response) => (response.data ? (store.sync(response.data) || response.data) : response)
}

export default ApiModule
