import React from "react"
import EnterInput from "../../../general/EnterInput";

class NewRow extends React.Component {

    constructor() {
        super();
    }

    render () {
        const addButtonTitle = `${I18n.t('buttons.add')} ${(I18n.t('tours.tour')).toLowerCase()}`
        const addButton = <button className='btn btn-success btn-xs' onClick={this.props.handleCreateOnClick}>
            {addButtonTitle}
        </button>

        return <tr id='new-tour' className='success'>
            <td>
                <EnterInput className='form-control input-sm m-b-0'
                            name='tour-name'
                            placeholder={I18n.t('general.title')}
                            onEnter={this.props.handleCreateOnClick}/>
            </td>
            <td className='fit'>
                <div className='pull-right'>
                    {addButton}
                </div>
            </td>
        </tr>
    }

}

export default NewRow
