import React, { useEffect, useRef } from "react"

const Editor = ({ name, title, placeholder, content, token }) => {
  const textareaEl = useRef(null)

  const getMediumInsertImage = (src) => {
    const div = document.createElement('div')
    div.className = 'medium-insert-images medium-insert-active'
    
    const figure = document.createElement('figure')
    figure.setAttribute('contenteditable', false)
    
    const img = document.createElement('img')
    img.setAttribute('src', src)
    
    figure.appendChild(img)
    div.appendChild(figure)

    return div
  }

  const adjustPastedImage = (event, root) => {
    Array.from(root.querySelectorAll('.medium-insert-active')).forEach(element => {
      const img = element.querySelector('img[src]');
      if (!img) {
        return
      }

      if (element.tagName.toLowerCase() !== 'p') {
        return
      }

      const src = img.getAttribute('src')
      element.after(getMediumInsertImage(src))
      element.parentNode.removeChild(element)
    })
  }

  const initEditor = () => {
    const editor = new MediumEditor(textareaEl.current, {
      placeholder: {
          text: placeholder
      },
      paste: {
          forcePlainText: false
      },
      imageDragging: false
    });
    
    editor.subscribe('editableInput', adjustPastedImage)

    const addons = {
      images: {	
        uploadScript: null,	
        deleteScript: null,	
        captionPlaceholder: I18n.t('posts.img_title'),
      },
      embeds: {	
        // oembedProxy: '//iframe.ly/api/iframely?api_key=4f55ad34857fcbc19e4d73&iframe=1'	
        oembedProxy: `/embed?token=${token}`,
        placeholder: I18n.t('posts.media_link'),
      },
    }

    $(textareaEl.current).mediumInsert({
        editor,
        addons
    });
  }

  useEffect(() => {
    initEditor()
  }, [])

  return <div className='form-group'>
    <h2>{title}</h2>
    <div>
      <textarea name={`post[${name}]`} ref={textareaEl} defaultValue={content}></textarea>
    </div>
  </div>
}

export default Editor
