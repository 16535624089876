import React, { useEffect, useState } from "react"
import ApiModule from "../../ApiModule";
import Team from "./TeamContainer/Team";

const Game = ({ onExpandClick, onScoreUpdate, scheduleId, matchId, colClass, onMatchLoaded }) => {
    const api = new ApiModule(false)

    const [hostDetails, setHostDetails] = useState({})
    const [guestDetails, setGuestDetails] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (matchId) {
            refreshMatch()
        } else {
            setHostDetails({})   
            setGuestDetails({})
        }
    }, [matchId])

    const getMatch = () => {
        return api.get('matches', matchId)
    }

    const refreshPlayers = () => {
        refreshMatch()
    }

    const refreshMatch = () => {
        setLoading(true)
        getMatch().then(data => {
            onMatchLoaded(data.schedule_id)
            setHostDetails(data?.host_team)
            setGuestDetails(data?.guest_team)
            setLoading(false)
        })
    }

    return <div className={colClass}>
        <div className='row'>
            <div className='col-md-12'>
                <div className='row'>
                <Team
                    key='host'
                    scheduleId={scheduleId}
                    matchId={matchId}
                    details={hostDetails}
                    opponentDetails={guestDetails}
                    onExpandClick={onExpandClick}
                    onScoreUpdate={onScoreUpdate}
                    onPlayerUpdate={refreshPlayers}
                    loading={loading}
                    side='host' />

                <Team
                    key='guest'
                    scheduleId={scheduleId}
                    matchId={matchId}
                    details={guestDetails}
                    opponentDetails={hostDetails}
                    onExpandClick={onExpandClick}
                    onScoreUpdate={onScoreUpdate}
                    onPlayerUpdate={refreshPlayers}
                    loading={loading}
                    side='guest' />
                </div>
            </div>
        </div>
    </div>
}

export default Game
