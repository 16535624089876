import React from "react"

class EditRow extends React.Component {

    constructor(props) {
        super();
        this.data = props.data
    }

    render () {
        const saveButton = <button className='btn btn-success btn-xs' onClick={this.props.handleSaveOnClick.bind(this, this.data.id)}>
            {I18n.t('buttons.save_short')}
        </button>
        const cancelButton = <button className='btn btn-default btn-xs m-l-1' onClick={this.props.handleCancelOnClick}>
            {I18n.t('buttons.cancel')}
        </button>
        const rowId = `season-${this.data.id}`

        return <tr key={this.data.id} id={rowId} className='info'>
            <td>
                <input type="text" className="form-control input-sm m-b-0"
                       defaultValue={this.data.name}
                       name='season-name'
                       placeholder={I18n.t('general.title')}/>
            </td>
            <td className='hidden-xs'>
                <input type="text" className="form-control input-sm m-b-0"
                       defaultValue={this.data.description}
                       name='season-description'
                       placeholder={I18n.t('general.title')}/>
            </td>
            <td>
                <label className="custom-control custom-checkbox" htmlFor="status">
                    <input type="checkbox"
                           defaultChecked={this.data.active}
                           id="status"
                           name='season-status'
                           className="custom-control-input"/>
                    <span className="custom-control-indicator"/>
                    {I18n.t('general.active')}
                </label>
            </td>
            <td className='fit'>
                <div className='pull-right'>
                    {saveButton}
                    {cancelButton}
                </div>
            </td>
        </tr>
    }

}

export default EditRow
