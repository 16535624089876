import React from "react"

class NewGame extends React.Component {
    constructor(props){
        super(props)
        this.game = props.game
        this.venueId = null
        this.timeRef = 'game-time'
    }

    componentDidUpdate () {
        const timeInput = this.refs[this.timeRef]
        $(timeInput).val(this.props.startTime)
    }

    getTourOptions () {
        let tourOptions = []
        if (this.props.lastTour) {
            tourOptions.push(<option key={-1} value={null}/>)
        }
        let tours = this.props.tours

        for (let id in tours) {
            if (tours.hasOwnProperty(id)) {
                let tour = tours[id]
                tourOptions.push(<option key={id} value={tour.id}>{tour.name}</option>)
            }
        }

        return tourOptions
    }

    getTeamOptions () {
        let teamOptions = [<option key={-1} value={null}/>]
        let teams = this.props.teams

        for (let id in teams) {
            if (teams.hasOwnProperty(id)) {
                let team = teams[id]
                teamOptions.push(<option key={id} value={team.id}>{team.name}</option>)
            }
        }

        return teamOptions
    }

    getVenueOptions () {
        let options = [<option key={-1} value={null}/>]
      
        if (this.props.venues.forEach) {
            this.props.venues.forEach(venue => {
                options.push(<option key={venue.id} value={venue.id}>{venue['short-name']}</option>)
            })
        }

        return options;
    }

    render () {
        const getVenueOptions = this.getVenueOptions()
        const tourOptions = this.getTourOptions()
        const teamOptions = this.getTeamOptions()

        return <tr className='new-game'>
            <td className='fit'>
                <input type="text"
                       ref={this.timeRef}
                       className="masked-input-time form-control input-sm m-b-0 game-time new-game-time"
                       defaultValue={this.props.startTime}
                       placeholder={I18n.t('placeholder.time')}/>
            </td>

            <td>
                <select className="form-control select2-sm select2-base new-game-venue"
                        defaultValue={9}
                        data-placeholder={I18n.t('th.venue')}
                        data-allow-clear="false">
                    {getVenueOptions}
                </select>
            </td>

            <td>
                <select className="form-control select2-sm select2-base new-game-tour"
                        defaultValue={this.props.lastTour}
                        data-placeholder={I18n.t('th.tour')}
                        data-allow-clear="false">
                    {tourOptions}
                </select>
            </td>

            <td>
                <select className="form-control select2-sm select2-base new-game-host"
                        data-placeholder={I18n.t('placeholder.host_team')}
                        data-allow-clear="false"
                        data-tags="true">
                    {teamOptions}
                </select>
            </td>

            <td>
                <select className="form-control select2-sm select2-base new-game-guest"
                        data-placeholder={I18n.t('placeholder.guest_team')}
                        data-allow-clear="false"
                        data-tags="true">
                    {teamOptions}
                </select>
            </td>

            <td className='fit text-right'>
                <input type='hidden' className='new-game-date-hidden' defaultValue={this.props.date}/>
                <input type='hidden' className='new-game-venue' defaultValue={this.venueId}/>
                <button className='btn btn-sm btn-primary btn-outline m-l-2' onClick={this.props.onNewSlotSubmit}>{I18n.t('buttons.add')}</button>
            </td>
        </tr>
    }
}

export default NewGame
