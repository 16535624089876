import React from "react"
import ApiModule from "../../ApiModule"
import ViewRow from "./ViewRow"
import EditRow from "./EditRow"
import NewRow from "./NewRow"
import Tours from "./tours/Tours"
import Leagues from "./leagues/Leagues"

const RESOURCE = 'stages'

class Stages extends React.Component {

    constructor(props) {
        super()
        this.api = new ApiModule()
        this.state = {
            list: props.list,
            tourList: null,
            leagueList: null,
            editRow: -1,
            selectedRow: -1,
            toDeleteRow: -1
        }

        this.handleSelectOnClick = this.handleSelectOnClick.bind(this)
        this.handleCreateOnClick = this.handleCreateOnClick.bind(this)
        this.handleEditOnClick = this.handleEditOnClick.bind(this)
        this.handleSaveOnClick = this.handleSaveOnClick.bind(this)
        this.handleCancelOnClick = this.handleCancelOnClick.bind(this)
        this.handleDeleteOnClick = this.handleDeleteOnClick.bind(this)
        this.initSortable = this.initSortable.bind(this)
    }

    handleSelectOnClick(id) {
        this.setState({
            selectedRow: id
        })

        this.setState({
            tourList: null,
            leagueList: null
        })
        this.getTourList(id)
        this.getLeagueList(id)
    }

    handleCreateOnClick() {
        const row = $(`#new-stage`)
        const stage = {
            name: row.find("[name='stage-name']").val(),
            playoff: row.find("[name='stage-playoff']").is(":checked")
        }

        this.api.create(`${RESOURCE}`, stage)
            .then(data => {
                row.find("[name='stage-name']").val('')
                row.find("[name='stage-playoff']").prop('checked', false)

                const playoff = this.isPlayoff(data.properties)
                this.setState(prevState => {
                    let list = prevState.list
                    list.push({
                        id: Number.parseInt(data.id, 10),
                        name: data.name,
                        playoff: playoff
                    })
                    return {
                        list: list
                    }
                })

                let message = stage.name
                if (playoff) {
                    message += ` (${I18n.t('admin.stages.playoff')})`
                }
                $.growl.success({
                    title: I18n.t('admin.stages.stage_created'),
                    message: message})
            })
            .catch(e => {
                const error = e && e.response && e.response.data && e.response.data.error
                    || I18n.t('general.unknown_error')

                $.growl.error({
                    title: I18n.t('general.not_saved'),
                    message: error});
            })
    }

    handleEditOnClick(id) {
        this.setState({
            editRow: id
        })
    }

    handleSaveOnClick(id) {
        const row = $(`#stage-${id}`)
        const stage = {
            name: row.find("[name='stage-name']").val(),
            playoff: row.find("[name='stage-playoff']").is(":checked")
        }

        this.api.update(RESOURCE, id, stage)
            .then(data => {
                const playoff = this.isPlayoff(data.properties)
                this.setState(prevState => {
                    let list = prevState.list
                    list.forEach(item => {
                        if (item.id === Number.parseInt(data.id, 10)) {
                            item.name = data.name
                            item.playoff = playoff
                        }
                    })
                    return {
                        editRow: -1,
                        list: list
                    }
                })

                let message = stage.name
                if (playoff) {
                    message += ` (${I18n.t('admin.stages.playoff')})`
                }
                $.growl.success({
                    title: I18n.t('admin.stages.stage_saved'),
                    message: message})

                this.focusNewInput()
            })
            .catch(e => {
                const error = e && e.response && e.response.data && e.response.data.error
                    || I18n.t('general.unknown_error')

                $.growl.error({
                    title: I18n.t('general.not_saved'),
                    message: error})
            })
    }

    isPlayoff(properties) {
        return properties.filter(prop => {
            return prop['property-name'] === 'IS_PLAYOFF'
                && prop['property-value'] === 'true'
        }).length > 0
    }

    handleCancelOnClick() {
        this.setState({
            editRow: -1
        })
    }

    handleDeleteOnClick(id) {
        this.setState({
            toDeleteRow: id
        })

        let index
        let name
        const list = this.state.list
        for (let i in list) {
            if (list[i].id === id) {
                index = i
                name = list[i].name
                break
            }
        }

        setTimeout(() => {
            const cfrm = `${I18n.t('general.delete')} ${I18n.t('admin.stages.stage')}: ${name}?`
            if (confirm(cfrm)) {
                this.api.delete('stages', id)
                    .then(data => {
                        const error = this.responseError(data)
                        if (error) {
                            $.growl.error({
                                title: I18n.t('admin.stages.not_deleted'),
                                message: error
                            })
                        } else {
                            this.setState(prevState => {
                                const selectedRow = prevState.selectedRow === id ? -1 : prevState.selectedRow
                                let list = prevState.list
                                list.splice(index, 1)
                                return {
                                    list: list,
                                    toDeleteRow: -1,
                                    selectedRow: selectedRow
                                }
                            })

                            $.growl.success({
                                title: I18n.t('admin.stages.stage_deleted'),
                                message: name
                            })
                        }

                        this.setState({
                            toDeleteRow: -1
                        })
                        this.focusNewInput()
                    })
            } else {
                this.setState({
                    toDeleteRow: -1
                })
            }
        }, 1)

    }

    focusNewInput() {
        $("#new-stage input[name='stage-name']").focus()
    }

    responseError(data) {
        return data && data.data && data.data.error || false
    }

    getTourList(stageId) {
        const url = Routes.admin_api_v1_stage_tours_path(stageId)
        this.api.getList(url)
            .then(data => {
                this.setState({
                    tourList: data
                })
            })
    }

    getLeagueList(stageId) {
        const url = Routes.admin_api_v1_stage_leagues_path(stageId)
        this.api.getList(url)
            .then(data => {
                this.setState({
                    leagueList: data
                })
            })
    }

    stageList() {
        const list = []
        this.state.list.forEach(stage => {
            let row
            if (stage.id === this.state.editRow) {
                row = <EditRow key={stage.id}
                               handleSaveOnClick={this.handleSaveOnClick}
                               handleCancelOnClick={this.handleCancelOnClick}
                               data={stage}/>
            } else {
                row = <ViewRow key={stage.id}
                               handleSelectOnClick={this.handleSelectOnClick}
                               handleEditOnClick={this.handleEditOnClick}
                               handleDeleteOnClick={this.handleDeleteOnClick}
                               selectedRow={this.state.selectedRow}
                               toDeleteRow={this.state.toDeleteRow}
                               data={stage}/>
            }

            list.push(row)
        })

        return list
    }

    componentDidMount() {
        this.initSortable('stages')
    }

    initSortable(type) {
        const selector = `.table-sortable.table-sortable-${type}`
        const group = $(selector).sortable({
            containerSelector: 'table',
            handle: 'i.icon-move',
            itemPath: '> tbody',
            itemSelector: 'tr',
            placeholder: '<tr class="placeholder">',
            onDrop: ($item, container, _super, event) => {
                const sorted = group.sortable('serialize').get()[0]
                const last = sorted[sorted.length - 1]
                if (!last || !last.id) {
                    sorted.pop()
                }
                const order = []
                let index = 0
                sorted.forEach(item => {
                    index++
                    order.push({
                        id: item.id,
                        identifier: index
                    })
                })
                const data = {
                    step: type,
                    order: order
                }
                const url = Routes.admin_api_v1_orders_path()
                this.api.send(url, data, 'order', 'patch')
                    .then(() => {
                        const item = I18n.t(`${type}.of`)
                        $.growl.success({
                            title: I18n.t('general.order_of_saved', {item: item}),
                            message: ''})
                    })
                _super($item, container)
            }
        })
    }

    render () {
        return <div className='row'>
            <div className='col-md-6'>
                <div className='table-responsive'>
                    <table className='table table-bordered table-striped table-sortable table-sortable-stages m-b-0'>
                        <thead>
                            <tr>
                                <th>{I18n.t('general.title')}</th>
                                <th className='text-capitalize'>{I18n.t('admin.stages.playoff')}</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {this.stageList()}
                            <NewRow handleCreateOnClick={this.handleCreateOnClick}/>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='col-md-3'>
                <Tours list={this.state.tourList} stageId={this.state.selectedRow} initSortable={this.initSortable}/>
            </div>

            <div className='col-md-3'>
                <Leagues list={this.state.leagueList} stageId={this.state.selectedRow} initSortable={this.initSortable}/>
            </div>
        </div>
    }

}

export default Stages
