import React from "react"
import moment from 'moment'
import Day from "./Day"
import NewDayGame from "./NewDayGame";
import ApiModule from "../../ApiModule";

class Schedule extends React.Component {

    constructor(props) {
        super()
        moment.locale('uk')

        this.api = new ApiModule()
        this.seasonId = props.season_id
        this.state = {
            schedule: [],
            edit: {},
            delete: {},
            tours: {},
            teams: {},
            venues: {}
        }

        this.handleEditGameOnClick = this.handleEditGameOnClick.bind(this)
        this.handleRemoveGameOnClick = this.handleRemoveGameOnClick.bind(this)
        this.handleSaveGameOnClick = this.handleSaveGameOnClick.bind(this)
        this.handleCancelEditOnClick = this.handleCancelEditOnClick.bind(this)
        this.handleNewSlotSubmit = this.handleNewSlotSubmit.bind(this)
        this.handlePastScheduleChange = this.handlePastScheduleChange.bind(this)
    }

    initMaskFields() {
        $('.masked-input-date-time').mask('99.99.9999 99:99')
        $('.masked-input-date').mask('99.99.99')
        $('.masked-input-time').mask('99:99')
    }

    initSelect2() {
        $('.select2-base').select2({
            dropdownAutoWidth: true
        }).on('select2:select', e => {
            const p = $(e.target).parent()
            p.next().find(':input:visible')[0].focus()
        })

        $(document).on('focus', '.select2', function() {
            $(this).siblings('.select2-base').select2('open');
        });
    }

    handleEditGameOnClick(id) {
        this.setState((prevState) => {
            let edit = prevState.edit
            edit[id] = true
            return {
                edit: edit
            }
        });
    }

    handleRemoveGameOnClick(id, event) {
        const tds = $(event.target).parents('tr').find('td')
        const confirmation = `${I18n.t('admin.schedule.remove_match')} ${tds[2].innerText} - ${tds[3].innerText}?`
        if (!confirm(confirmation)) {
            return false
        }

        this.api.delete('schedules', id)
            .then(responce => {
                this.deleteSchedule(id)

                $.growl.success({
                    title: I18n.t('admin.schedule.match_deleted'),
                    message: ''});
            })
            .catch(this.showErrorAlert)

        this.setState((prevState) => {
            let del = prevState.delete
            del[id] = true
            return {
                'delete': del
            }
        });
    }

    showErrorAlert(data) {
        const error = data.response &&
            data.response.data &&
            data.response.data.error ||
            I18n.t('general.unknown_error')

        $.growl.error({
            title: I18n.t('general.error'),
            message: error })
    }

    handleSaveGameOnClick(id) {
        const row = $('#schedule-row-' + id)
        const date = moment(row.find('.game-date').val(), 'DD.MM.YY').format('YYYY-MM-DD')
        const time = row.find('.game-time').val()
        const tour = row.find('.game-tour').val()
        const venue = row.find('.game-venue').val()
        const host = row.find('.game-host').val()
        const guest = row.find('.game-guest').val()

        const schedule = {
            match_on: date,
            match_at: time,
            tour_id: tour,
            venue_id: venue,
            host_team_id: host,
            guest_team_id: guest
        }

        this.api.update('schedules', id, schedule)
            .then(savedGame => {
                this.deleteSchedule(id)
                this.addSchedule(savedGame)

                this.setState((prevState) => {
                    let edit = prevState.edit
                    edit[id] = false
                    return {
                        edit: edit
                    }
                });

                const message = `${moment(savedGame['match-on']).format('D MMM')} ${savedGame['match-at']},<br/>${this.teamName(savedGame, 'host')} - ${this.teamName(savedGame, 'guest')}`
                $.growl.success({
                    title: I18n.t('admin.schedule.match_saved'),
                    message: message});
            })
            .catch(this.showErrorAlert)

    }

    handleCancelEditOnClick(id) {
        this.setState((prevState) => {
            let edit = prevState.edit
            edit[id] = false
            return {
                edit: edit
            }
        });
    }

    handleNewSlotSubmit(e) {
        e.preventDefault()
        const row = $(e.target).parents('.new-game')
        const hiddenDate = row.find('.new-game-date-hidden').val()
        const matchOn = hiddenDate || moment(row.find('.new-game-date').val(), 'DD.MM.YY').format('YYYY-MM-DD')
        const matchAtInput = row.find('.new-game-time')
        const tour = row.find('.new-game-tour').val()
        const hostInput = row.find('.new-game-host')
        const guestInput = row.find('.new-game-guest')
        const venue = row.find('.new-game-venue').val()

        const schedule = {
            match_on: matchOn,
            match_at: matchAtInput.val(),
            tour_id: tour,
            venue_id: venue,
            host_team_id: hostInput.val(),
            guest_team_id: guestInput.val()
        }

        this.api.create('schedules', schedule)
            .then(savedGame => {
                this.addSchedule(savedGame)
                // matchAtInput.val('')
                hostInput.val('').trigger("change")
                guestInput.val('').trigger("change")

                const message = `${moment(savedGame['match-on']).format('D MMM')} ${savedGame['match-at']},<br/>${this.teamName(savedGame, 'guest')} - ${this.teamName(savedGame, 'host')}`
                $.growl.success({
                    title: I18n.t('admin.schedule.match_added'),
                    message: message});

            })
            .catch(this.showErrorAlert)
    }

    handlePastScheduleChange(e) {
        this.setState({
            games: [],
            schedule: [],
        })
        this.getScheduleList(e.target.value)
    }

    componentDidUpdate() {
        this.initMaskFields()
        this.initSelect2()
    }

    componentDidMount() {
        this.getScheduleList()
        this.getTours()
        this.getTeams()
        this.getVenues()
    }

    getScheduleList(past) {
        const url = past ? `schedules?past=${past}` : 'schedules'
        const inclusions = ['hosts', 'guests', 'tour', 'league', 'venue']
        this.api.list(url, inclusions)
            .then(data => {
                this.addSchedule(data)
            })
    }

    teamName(game, side) {
        const sides = `${side}s`
        return game[sides] && game[sides].name || game[`${side}-draft-team`]
    }

    deleteSchedule(id) {
        this.setState((prevState) => {
            let games = prevState.games
            let schedule = prevState.schedule

            for (let i in games) {
                if (games[i].id === id) {
                    games.splice(i, 1)
                    break
                }
            }

            for (let g in schedule) {
                for (let i in schedule[g]) {
                    let game = schedule[g][i]
                    if (game.id === id) {
                        if (schedule[g].length === 1) {
                            delete schedule[g]
                        } else {
                            schedule[g].splice(i, 1)
                        }
                        break
                    }
                }
            }

            return {
                games: games,
                schedule: schedule
            }
        })
    }

    addSchedule(data) {
        this.setState((prevState) => {
            if (!Array.isArray(data)) {
                data = [data]
            }
            let games = prevState.games || []
            games = games.concat(data)

            games.sort(function (a, b) {
                const amoment = moment(`${a['match-on']} ${a['match-at']}`)
                const bmoment = moment(`${b['match-on']} ${b['match-at']}`)
                return (amoment.isAfter(bmoment)) ? 1 : ((amoment.isBefore(bmoment)) ? -1 : 0)
            })

            let grouped = games.reduce(function (r, a) {
                r[a['match-on']] = r[a['match-on']] || [];
                r[a['match-on']].push(a);
                return r;
            }, Object.create({}));

            return {
                games: games,
                schedule: grouped
            }
        })
    }

    getTours () {
        this.api.list('tours', null, `seasons/${this.seasonId}`)
            .then(data => {
                data.sort((a, b) => {
                    return parseInt(b.id, 10) - parseInt(a.id, 10)
                })
                this.setState({
                    tours: data
                })
            })
    }

    getTeams () {
        this.api.list('teams', null, `seasons/${this.seasonId}`)
            .then(data => {
                this.setState({
                    teams: data
                })
            })
    }

    getVenues () {
      this.api.list('venues', null)
          .then(data => {
              this.setState({
                  venues: data
              })
          })
  }

    getScheduleDays () {
        let scheduleDays = []
        let schedule = this.state.schedule
        for (let date in schedule) {
            if (schedule.hasOwnProperty(date)) {
                const momentDate = moment(date)
                const dayTitle = momentDate.format('D MMMM YYYY, dddd')
                scheduleDays.push(<Day key={date}
                                       date={date}
                                       dayTitle={dayTitle}
                                       past={momentDate.isBefore(moment().startOf('day'))}
                                       games={schedule[date]}
                                       tours={this.state.tours}
                                       teams={this.state.teams}
                                       venues={this.state.venues}
                                       inEdit={this.state.edit}
                                       inDeleting={this.state.delete}
                                       onEditClick={this.handleEditGameOnClick}
                                       onRemoveClick={this.handleRemoveGameOnClick}
                                       onSaveClick={this.handleSaveGameOnClick}
                                       onCancelEditClick={this.handleCancelEditOnClick}
                                       onNewSlotSubmit={this.handleNewSlotSubmit}
                />)
            }
        }

        return scheduleDays
    }

    render () {
        return <div>
            <div className='col-md-8'>
                <div className='row'>
                    <div>
                        {this.getScheduleDays()}

                        <div className="panel">
                            <div className='panel-heading'>
                                <div className="panel-title">{I18n.t('admin.schedule.new_match_day')}</div>
                            </div>
                            <div className='panel-body '>
                                <NewDayGame
                                    teams={this.state.teams}
                                    tours={this.state.tours}
                                    venues={this.state.venues}
                                    onNewSlotSubmit={this.handleNewSlotSubmit}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col-md-4'>

            <div className='form-group'></div>
                <label htmlFor='past-schedule'>Отображать прошедшее расписание</label>
                <select id='past-schedule'className='form-control' onChange={this.handlePastScheduleChange}>
                    <option value='0'>Только предстоящие туры</option>
                    <option value='1'>Начиная 2 недели назад</option>
                    <option value='2'>Начиная 4 недели назад</option>
                    <option value='3'>Расписание за весь сезон</option>
                </select>
            </div>
        </div>
    }
}

export default Schedule
