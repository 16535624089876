import React from "react"
class Comment extends React.Component {
    render () {
        return <li className='list-group-item'>
            <div className='text-default comment-author'>
                {this.props.author}
            </div>
            <div className='comment-title'>
                <a href={this.props.link}>{this.props.title}</a>
            </div>
            <div className='text-light comment-data'>
                {this.props.date}
            </div>
        </li>;

    }
}

export default Comment
